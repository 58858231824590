import React, { useEffect, useState } from "react";
import { Dialog, Grid, MenuItem, Select, TextField } from "@mui/material";
import { DashboardLayout } from "@/components/layouts";
import Box from '@mui/material/Box';
import { SummaryCard } from "@/components/molecules/Dashboard/SummaryCard";
import { useDashboardStyles } from "@/static/stylesheets/screens";
import { BazaarReportCard } from "@/components/molecules/Bazaars/BazaarReportCard";
import { BazaarCard, BazaarCounter, BazaarsPlanList, } from "@/components/molecules/Bazaars";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import CommonSelect from "@/components/atoms/CommonSelect/CommonSelect";
import { AppService } from "../../service/AllApiData.service";
import dayjs, { Dayjs } from 'dayjs';
import Stack from '@mui/material/Stack';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { DateRangePicker } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import DistrictPicker from "@/components/atoms/LocationPickers/DistrictPicker";
import CityPicker from "@/components/atoms/LocationPickers/CityPicker";
import { string } from "yup";
import DatePicker from "react-multi-date-picker";
import type { Value } from "react-multi-date-picker"
import { getDateRange, getFormatedDateRange } from "@/helper/date";
import { formatIndianRupee } from "../AgentDetails/AgentReport";
import { getFinancialYears } from "@/helper/util";

const Dashboard = () => {
    const [getAllBazar, setGetAllBazar] = useState([]);
    const [getyearSummeryData, setGetYearSummeryData] = useState({
        agent: 0, bazaar: 0, commission: 0, wholeseller: 0,
    });
    const [getTotalBazaar, setTotalBazaar] = useState("");
    const [getWholesellers, setWholesellers] = useState("");
    const [getRevenue, setRevenue] = useState("");
    const [getBill, setBill] = useState("");
    const [getAgent, setAgent] = useState("");
    const [getCommission, setCommission] = useState("");
    const [getCustomer, setCustomer] = useState("");
    const [activeTab, setActiveTab] = useState("today")
    const [activeTab1, setActiveTab1] = useState("today")
    const [yearval, setYearval] = useState<any>(new Date().getFullYear())
    const [active, setActive] = useState(null)
    const [todayrespo, setTodayrespo] = useState(null)
    const [todayreport, setTodayreport] = useState(null)
    const [reportBazzarlist, setReportBazzarlist] = useState<any>([])
    const [planBazzarlist, setPlanBazzarlist] = useState<any>([])
    const [summaryBazzarlist, setSummaryBazzarlist] = useState<any>([])
    const [selectreportbazzar, setselectreportbazzar] = useState<any>([])
    const [selectreportbazzar2, setselectreportbazzar2] = useState<any>([])
    // const [selectreportbazzar3, setselectreportbazzar3] = useState<any>([])
    const [districtData, setDistrictData] = useState<any>([])
    const [selectreportdistrict, setselectreportdistrict] = useState<any>([])
    const [selectreportdistrict2, setselectreportdistrict2] = useState<any>([])
    const [selectreportdistrict3, setselectreportdistrict3] = useState<any>([])
    const [stateData, setStateData] = useState<any>([])
    const [selectreportstate, setselectreportstate] = useState<any>([])
    const [selectreportstate2, setselectreportstate2] = useState<any>([])
    const [selectreportstate3, setselectreportstate3] = useState<any>([])
    const [cityData, setCityData] = useState<any>([])
    const [selectreportcity, setselectreportcity] = useState<any>([])
    const [selectreportcity2, setselectreportcity2] = useState<any>([])
    const [selectreportcity3, setselectreportcity3] = useState<any>([])
    const [getPlanname, setgetPlanname] = useState<any>([])
    const [selectplanname, setselectplanname] = useState<any>([])
    const [selectreportbazzar3, setselectreportbazzar3] = useState<any>([])
    const [daterangevalue, setdaterangevalue] = useState<any>([]);
    const [daterangevalue1, setdaterangevalue1] = useState<any>([]);
    const [walletdatas, setwalletdatas] = useState<any>()
    const [Subscribers, setSubscribers] = useState(0)
    const [Plans, setPlans] = useState(0)
    const [mapdata, setmapdata] = useState<any>([])
    const [count, setcount] = useState<any>(1)
    const [getplandata, setplandata] = useState<any>([]);
    const [reports,setReports] = useState<any>();
    const [planSold, setPlanSold] = useState<any>();
    const [reportStateList,setReportStateList] = useState<any>([]);
    const [reportDisList,setReportDisList] = useState<any>([]);
    const [reportCityList,setReportCityList] = useState<any>([]);
    const [planStateList,setPlanStateList] = useState<any>([]);
    const [planDisList,setPlanDisList] = useState<any>([]);
    const [planCityList,setPlanCityList] = useState<any>([]);
    const [summaryStateList,setSummaryStateList] = useState<any>([]);
    const [summaryDisList,setSummaryDisList] = useState<any>([]);
    const [summaryCityList,setSummaryCityList] = useState<any>([]);
    const [allDis, setAllDis] = useState<any>([]);
    const [AllState, setAllState] = useState<any>([]);
    const [AllCity, setAllCity] = useState<any>([]);
    const id = localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") || '')?.id : ""
    const [finYears,setFinYears] = useState<any>([]);

    useEffect(()=>{
        getAllDis({},"");
        getAllState({},"");
        getAllCity({},"");

        setFinYears(getFinancialYears())
    },[])

    useEffect(()=>{
        const states = reportStateList.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        const bazaars = reportBazzarlist.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        const dists = reportDisList.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        const cities = reportCityList.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        
        let params = {};
        if(states?.length){
            params = {...params,state:states.join(",")}
        }
        if(dists?.length){
            params = {...params,district:dists.join(",")}
        }
        if(cities?.length){
            params = {...params,city:cities.join(",")}
        }
        if(bazaars?.length){
            params = {...params,bazaar:bazaars.join(",")}
        }
        if(activeTab){
            const date = activeTab === "range" ? getFormatedDateRange(daterangevalue) : getDateRange(activeTab) as any
            params = {...params,start_date:date?.start_date,end_date: date?.end_date}
        }
        getDashboardReports(params)
    },[reportStateList,reportBazzarlist,reportDisList,reportCityList,activeTab])

    useEffect(()=>{
        const states = planStateList.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        const bazaars = planBazzarlist.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        const dists = planDisList.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        const cities = planCityList.filter((item:any)=>item.status === true).map((item:any)=>item.value)
        
        let params = {};
        if(states?.length){
            params = {...params,state:states.join(",")}
        }
        if(dists?.length){
            params = {...params,district:dists.join(",")}
        }
        if(cities?.length){
            params = {...params,city:cities.join(",")}
        }
        if(bazaars?.length){
            params = {...params,bazaar:bazaars.join(",")}
        }
        if(activeTab1){
            const date = activeTab1 === "range" ? getFormatedDateRange(daterangevalue1) : getDateRange(activeTab1) as any
            params = {...params,start_date:date?.start_date,end_date: date?.end_date}
        }
        getDashboardPlansSold(params)
    },[planStateList,planBazzarlist,planDisList,planCityList,activeTab1])

    const getAllDis = async (param: any, type: any) => {
        const responseJson = await AppService.getTotalDistrict(param)
        setAllDis(responseJson.data);
      };
      const getAllState = async (param: any, type: any) => {
        const responseJson = await AppService.getTotalCity(param)
        setAllState(responseJson.data);
      };
      const getAllCity = async (param: any, type: any) => {
        const responseJson = await AppService.getAllCity(param)
        setAllCity(responseJson.data.results);
      };
    
      const getAllStateData = async (params: any,setState:any) => {
        try {
          const responseJson = await AppService.getAllStateByBazaar(params);
      
          const tempState = responseJson.data.results.flatMap((row: any) =>
            row.bazaar_state.map((id: any) => {
              const stateItem = AllState.find((item: any) => item.id === id);
              if (stateItem) {
                return {
                  label: stateItem.state,
                  value: id,
                };
              }
              return null; // Handle cases where a matching state is not found
            })
          );
      
          console.log("logger123...", AllState, responseJson.data, tempState);
          setState(tempState);
        } catch (error) {
          // Handle errors here
          console.error(error);
        }
      };
    
      const getAllDisData = async (params:any,setState:any) => {
        
        const responseJson = await AppService.getAllDistricByState(params);
        const tempState = responseJson.data.results.flatMap((row: any) =>
        row.district.map((item: any) => {
            return {
              label: item.district,
              value: item.id,
            };
        })
      );
    
      console.log("logger123...", allDis, responseJson.data, tempState);
        setState(tempState);
      };
      const getAllCityData = async (params:any,setState:any) => {
        const responseJson = await AppService.getAllCityByDis(params);
        const tempState = responseJson.data.results.flatMap((row: any) =>
        row.city.map((item: any) => {
            return {
              label: item.city,
              value: item.id,
            };
        })
      );
        setState(tempState);
      };

    const getDashboardReports = async (params:any={}) => {
        try {
          const response = await AppService.getAllreportfilterbazzar(params);
          console.log("Dashboard Reports...", response)
          setReports(response.data)
          
        } catch (err:any) {
          console.log("ERROR ====>",err.message)
        }
      }

      const getDashboardPlansSold = async (params:any={}) => {
        try {
          const response = await AppService.getAllplanfilterbazzar(params);
          console.log("Dashboard Plan Sold...", response)
          setPlanSold(response.data)
          
        } catch (err:any) {
          console.log("ERROR ====>",err.message)
        }
      }

    //   useEffect(()=>{
    //     getDashboardReports()
    //   },[])

    //   useEffect(()=>{
    //     getDashboardPlansSold()
    //   },[])

    const getAllLists = async () => {
        const responseJson = await AppService.getAllDashBazaarLists();

        // setTotalBazaar(responseJson.data.bazaar);
        // setWholesellers(responseJson.data.wholeseller);
        // setRevenue(responseJson.data.revenue);
        // setBill(responseJson.data.bill);
        // setAgent(responseJson.data.agent);
        // setCommission(responseJson.data.commission);
        // setCustomer(responseJson.data.customer);
    };

    const getwholesellerdata = async () => {
        const responseJson = await AppService.getAllListwholesellerNew();
        let data = await responseJson.data.results
        let localStoragedata = localStorage.getItem("agency") ? JSON.parse(localStorage.getItem("agency") || '').id : ""
        data?.map((x: any) => {
            if (localStoragedata == x.wholeseller_agent) {
                mapdata.push(x)
                setmapdata([...mapdata])
                setSubscribers(x.wholeseller_agent?.length)
                setPlans(x.wholeseller_agent?.length)
                console.log(mapdata, "responseJson++")
            }
        })
    }
    const getPlans = async() =>{
        const responseJson = await AppService.getPlans({page:count});
        if(responseJson.status == 200){
            setcount((prev :any) => prev + 1)
            setplandata((prev:any)=>[...prev,...responseJson.data.results])
        }
        
    }

    useEffect(() => {
        getPlans()
    }, [count])
    useEffect(()=>{
        getwholesellerdata()
    },[])


    const [value, setValue] = React.useState<Dayjs | null>(
        dayjs('2021-08-18T21:11:54'),
    );

    const handleChange = async (newValue: any) => {
        const formet: any = dayjs(newValue)
        setYearval(String(formet["$y"]))
        // const responseJson = await AppService.getAllSummerys(String(formet["$y"]))
        // console.log('responseJson', yearval)
        setValue(formet);
    };
    const getAllListsMain = async () => {
        let params = {
            page: 1
          }
        const responseJson = await AppService.getAllBazarList(params);
        setGetAllBazar(responseJson.data.results.slice(0, 9));
    };

    const walletdata = async (id: any) => {
        const responseJson = await AppService.walletdata(id);
        setwalletdatas(responseJson.data)
        // console.log(responseJson.data, "datas")
    }

    useEffect(() => {
        getAllLists();
        getAllListsMain();
        walletdata(id)
    }, []);

    const classes = useDashboardStyles();


    const [open, setOpen] = React.useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const navigate = useNavigate();
    const handleClick = () => navigate('/bazaars');


    const handleToggle = async (e: any) => {
        setActiveTab(e)
        setdaterangevalue([]);
    };

    const handleToggle1 = async (e: any) => {
        setActiveTab1(e)
        setdaterangevalue1([]);
    };
    const handletodayAction = async (val?: any) => {
        setActive(val)
        const responseJson = await AppService.getAllplanToday(val)
        setTodayrespo(responseJson?.data?.plan)
    }
    console.log('todayrespo', todayreport, 'todayplan', todayrespo)

    const handleSummaryStateChange = (e:any,selectboxName: string, id: string) => {
        if (selectboxName === 'State') {
            const updatedData = summaryStateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            console.log("logger123...",updatedData,id,AllState)
            getAllDisData({ids},setSummaryDisList);
            setSummaryStateList(updatedData);
          }
          if (selectboxName === 'District') {
      
            const updatedData = summaryDisList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            getAllCityData({ids},setSummaryCityList);
      
            setSummaryDisList(updatedData);
          }
          if (selectboxName === 'City') {
            setSummaryCityList((prev: any) => {
              return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
          }
          if (selectboxName === 'Bazaar') {
            const updatedData = summaryBazzarlist.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            getAllStateData({ids},setSummaryStateList);
            setSummaryBazzarlist(updatedData);
            
        }
        console.log("logger123...",selectboxName,id)
    }

    const handleRemarksStateChange = (e:any,selectboxName: string, id: string) => {
        if (selectboxName === 'State') {
            const updatedData = reportStateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            console.log("logger123...",updatedData,id,AllState)
            getAllDisData({ids},setReportDisList);
            setReportStateList(updatedData);
          }
          if (selectboxName === 'District') {
      
            const updatedData = reportDisList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            getAllCityData({ids},setReportCityList);
      
            setReportDisList(updatedData);
          }
          if (selectboxName === 'City') {
            setReportCityList((prev: any) => {
              return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
          }
          if (selectboxName === 'Bazaar') {
            const updatedData = reportBazzarlist.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            getAllStateData({ids},setReportStateList);
            setReportBazzarlist(updatedData);
            
        }
        console.log("logger123...",selectboxName,id)
    }

    const handlePlanStateChange = (e:any,selectboxName: string, id: string) => {
        if (selectboxName === 'State') {
            const updatedData = planStateList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            console.log("logger123...",updatedData,id,AllState)
            getAllDisData({ids},setPlanDisList);
            setPlanStateList(updatedData);
          }
          if (selectboxName === 'District') {
      
            const updatedData = planDisList.map((item: any) => item.value === id ? { ...item, status: !item.status } : item);
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            getAllCityData({ids},setPlanCityList);
      
            setPlanDisList(updatedData);
          }
          if (selectboxName === 'City') {
            setPlanCityList((prev: any) => {
              return prev.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            });
          }
          if (selectboxName === 'Bazaar') {
            const updatedData = planBazzarlist.map((item: any) => item.value === id ? { ...item, status: !item.status } : item)
            const ids = updatedData.filter((item:any)=>item.status===true).map((item:any)=>item.value).join(",");
            getAllStateData({ids},setPlanStateList);
            setPlanBazzarlist(updatedData);
            
        }
        console.log("logger123...",selectboxName,id)
    }

    const handleStateChange2 = (e: any, selectboxName: string, id: string) => {
        const { checked } = e.target;
        if (selectboxName === 'All Bazaars') {
            if (checked === true) {
                setselectreportbazzar2((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportbazzar2?.filter((im: any) => im !== id)
                setselectreportbazzar2([...filterbazzar])
            }
        }
        if (selectboxName === 'All States') {
            if (checked === true) {
                setselectreportstate2((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportstate2?.filter((im: any) => im !== id)
                setselectreportstate2([...filterbazzar])
            }
        }
        if (selectboxName === 'All Cities') {
            if (checked === true) {
                setselectreportcity2((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportcity2?.filter((im: any) => im !== id)
                setselectreportcity2([...filterbazzar])
            }
        }
        if (selectboxName === 'All District') {
            if (checked === true) {
                setselectreportdistrict2((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportdistrict2?.filter((im: any) => im !== id)
                setselectreportdistrict2([...filterbazzar])
            }
        }
    }

    const handleStateChange3 = (e: any, selectboxName: string, id: string) => {
        const { checked } = e.target;
        if (selectboxName === 'All Bazaars') {
            if (checked === true) {
                setselectreportbazzar3((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectreportbazzar3?.filter((im: any) => im !== id)
                setselectreportbazzar3([...filterbazzar])
            }
        }
        if (selectboxName === "Plan Name") {
            if (checked === true) {
                setselectplanname((prev: any) => ([...prev, id]))
            } else {
                const filterbazzar = selectplanname?.filter((im: any) => im !== id)
                setselectplanname([...filterbazzar])
            }
        }

    }

    const getAllbazzarlist = async () => {
        const responceJson = await AppService?.getAllBazaar()
        const bazaardata = responceJson?.data?.results?.map((it: any) => {
            return {
                label: it?.bazaar_name,
                value: it?.id
            }
        }
        )
        setSummaryBazzarlist([...bazaardata])
        setReportBazzarlist([...bazaardata])
        setPlanBazzarlist([...bazaardata])
    }
    const getTotalDistrictData = async () => {
        const responseJson = await AppService.getTotalDistrict();
        const data = responseJson?.data?.map((it: any) => {
            return {
                label: it?.district,
                value: it?.id
            }
        })
        setDistrictData([...data]);
    };
    const getTotalCities = async () => {
        const responseJson = await AppService.getAllLIstCity();
        const data = responseJson?.data?.results?.map((it: any) => {
            return {
                label: it?.city,
                value: it?.id
            }
        })
        setCityData([...data]);
    };
    const getTotalStates = async () => {
        const responseJson = await AppService.getTotalStates();
        const data = responseJson?.data?.map((it: any) => {
            return {
                label: it?.state,
                value: it?.id
            }
        })
        setStateData([...data]);
    };
    useEffect(() => {
        getAllbazzarlist()
        getTotalDistrictData()
        getTotalCities()
        getTotalStates()
    }, [])
    // const getreportbazzar = async (selectreportbazzar: any) => {
    //     const namefilter = bazzarlist?.filter((it: any) => selectreportbazzar?.includes(it?.value))?.map((im: any) => im?.label);
    //     if (namefilter?.length > 0) {
    //         const responseJson = await AppService?.getAllreportfilterbazzar(namefilter?.join(','))
    //         console.log('responseJson', responseJson?.data)
    //     }
    // }
    // const getplanbazzar = async (selectreportbazzar: any) => {
    //     const namefilter = bazzarlist?.filter((it: any) => selectreportbazzar?.includes(it?.value))?.map((im: any) => im?.label);
    //     if (namefilter?.length > 0) {
    //         const responseJson = await AppService?.getAllplanfilterbazzar(namefilter?.join(','))
    //         console.log('responseJson2', responseJson?.data)
    //     }
    // }
    // const getplanbazzar2 = async (selectreportbazzar: any) => {
    //     const namefilter = bazzarlist?.filter((it: any) => selectreportbazzar?.includes(it?.value))?.map((im: any) => im?.label);
    //     if (namefilter?.length > 0) {
    //         const responseJson = await AppService?.getAllplanfilterbazzar(namefilter?.join(','))
    //         console.log('responseJson3', responseJson?.data)
    //     }
    // }
    const getAllplannames = async () => {
        const responseJson = await AppService.getAllPlansData()
        const data = responseJson?.data?.results?.map((im: any) => {
            return {
                label: im?.plan_name,
                value: Math.floor(Math.random() * 999999)
            }
        })
        setgetPlanname([...data])
    }

    const totaleEarningCount = async (id: any) => {
        let data = await AppService.totaleEarningCount(id)
        console.log(data?.data)
        setCommission(data?.data?.TotalCommission)
    }

    const wholesellerCout = async (id: any) => {
        let data = await AppService.wholesellerCount(id)
        setWholesellers(data?.data?.total_wholeseller_count)
    }

    useEffect(() => {
        getAllplannames()
        wholesellerCout(id)
        totaleEarningCount(id)
    }, [])
    // useEffect(() => {
    //     getreportbazzar(selectreportbazzar)
    // }, [selectreportbazzar?.length])

    // useEffect(() => {
    //     getplanbazzar(selectreportbazzar2)
    // }, [selectreportbazzar2?.length])

    // useEffect(() => {
    //     getplanbazzar2(selectreportbazzar3)
    // }, [selectreportbazzar3?.length])

    useEffect(() => {
        if(daterangevalue?.length == 2){
            // const date = daterangevalue.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setActiveTab("range");
        }
    },[daterangevalue])

    useEffect(() => {
        if(daterangevalue1?.length == 2){
            // const date = daterangevalue1.map((item:any)=>`${item.day}/${item.month.number}/${item.year}`).join(",")
            setActiveTab1("range");
        }
    },[daterangevalue1])
    return (
        <>
            <DashboardLayout>
                <div className={classes.root}>
                    <div className={classes.summaryTitle}>
                        <p>Summary </p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                {/* <DesktopDatePicker
                                    views={["year"]}
                                    inputFormat={yearval}
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(params) => <TextField {...params} />}
                                /> */}
                                <Select
                                    value={yearval}
                                    variant="outlined"
                                    onChange={(e:any)=>setYearval(e.target.value)}
                                    sx={{width:"140px"}}
                                >
                                    {
                                        finYears.map((item:any)=>(
                                            <MenuItem value={item.value}>{item.label}</MenuItem>
                                        ))
                                    }
                                </Select>
                            </Stack>
                        </LocalizationProvider>
                    </div>
                    
                    <div className="bazaarFilters">
                        <CommonSelect
                            handleSelect={handleSummaryStateChange}
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={summaryBazzarlist}
                            selectedIds={summaryBazzarlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleSummaryStateChange}
                            label={"State"}
                            hint={"Search"}
                            options={summaryStateList}
                            selectedIds={summaryStateList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleSummaryStateChange}
                            label={"District"}
                            hint={"Search"}
                            options={summaryDisList}
                            selectedIds={summaryDisList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleSummaryStateChange}
                            label={"City"}
                            hint={"Search"}
                            options={summaryCityList}
                            selectedIds={summaryCityList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                    </div>

                    <div>
                        <SummaryCard 
                        years={yearval}
                        bazaars={summaryBazzarlist}
                        states={summaryStateList}
                        districts={summaryDisList}
                        cities={summaryCityList}
                        />
                    </div>

                    <div className={classes.commonTitle}>
                        <p>Bazaar Report</p>
                    </div>

                    <div className="bazaarButtons">
                        <div onClick={() => {
                            handleToggle('today');
                        }}><ActionButton variant={activeTab === 'today' ? "primary" : "default"} title={"Today"} /></div>
                        <div onClick={() => {
                            handleToggle('this_week');
                        }}><ActionButton variant={activeTab === 'this_week' ? "primary" : "default"} title={"This Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle('last_week');
                        }}><ActionButton variant={activeTab === 'last_week' ? "primary" : "default"} title={"Last Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle('this_month');
                        }}><ActionButton variant={activeTab === 'this_month' ? "primary" : "default"} title={"This Month"} />
                        </div>
                        <div onClick={() => {
                            handleToggle('last_month');
                        }}><ActionButton variant={activeTab === 'last_month' ? "primary" : "default"} title={"Last Month"} />
                        </div>
                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={daterangevalue} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setdaterangevalue} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <DateRangePicker
                                    label="Year"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(startProps: any, endProps: any) => (
                                        <React.Fragment>
                                            <TextField {...startProps} />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} />
                                        </React.Fragment>
                                    )}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>


                    <div className="bazaarFilters pt-[20px]">
                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={reportBazzarlist}
                            selectedIds={reportBazzarlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"State"}
                            hint={"Search"}
                            options={reportStateList}
                            selectedIds={reportStateList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"District"}
                            hint={"Search"}
                            options={reportDisList}
                            selectedIds={reportDisList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handleRemarksStateChange}
                            label={"City"}
                            hint={"Search"}
                            options={reportCityList}
                            selectedIds={reportCityList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                    </div>

                    <div className="bazaarReport">
                        <BazaarReportCard
                            title="Total Retailers"
                            count={reports?.retailer}
                            revenue="Total revenue Earned"
                            prize={formatIndianRupee(reports?.revenue || 0)}
                            bills="No of Orders"
                            billsNumber={reports?.bill || 0}
                        />
                        <BazaarReportCard
                            title="Total Agents"
                            count={reports?.agent || 0}
                            revenue="Commission Paid to Agent"
                            prize={formatIndianRupee(reports?.commission || 0)}
                            bills="Customer Generated by Agent"
                            billsNumber={reports?.customer || 0}
                        />
                    </div>

                    <div className={classes.commonTitle}>
                        <p>Plan Sold</p>
                        <p className="moreButton" onClick={handleClickOpen}>See all</p>
                    </div>

                    <div className="bazaarButtons">
                    <div onClick={() => {
                            handleToggle1('today');
                        }}><ActionButton variant={activeTab1 === 'today' ? "primary" : "default"} title={"Today"} /></div>
                        <div onClick={() => {
                            handleToggle1('this_week');
                        }}><ActionButton variant={activeTab1 === 'this_week' ? "primary" : "default"} title={"This Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('last_week');
                        }}><ActionButton variant={activeTab1 === 'last_week' ? "primary" : "default"} title={"Last Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('this_month');
                        }}><ActionButton variant={activeTab1 === 'this_month' ? "primary" : "default"} title={"This Month"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('last_month');
                        }}><ActionButton variant={activeTab1 === 'last_month' ? "primary" : "default"} title={"Last Month"} />
                        </div>
                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={daterangevalue1} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setdaterangevalue1} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <DateRangePicker
                                    label="Year"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(startProps: any, endProps: any) => (
                                        <React.Fragment>
                                            <TextField {...startProps} />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} />
                                        </React.Fragment>
                                    )}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>

                    <div className="bazaarFilters pt-[20px]">
                    <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={planBazzarlist}
                            selectedIds={planBazzarlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"State"}
                            hint={"Search"}
                            options={planStateList}
                            selectedIds={planStateList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"District"}
                            hint={"Search"}
                            options={planDisList}
                            selectedIds={planDisList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"City"}
                            hint={"Search"}
                            options={planCityList}
                            selectedIds={planCityList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                    </div>

                    <div className="counterCard">
                        <BazaarCounter data={planSold} />
                    </div>

                    <div className="planList">
                        <BazaarsPlanList data={planSold?.plan_sold_data || []}/>
                    </div>

                    <div className={classes.commonTitle}>
                        <p>All Bazaar</p>
                        <p className="moreButton" onClick={handleClick}>See all</p>
                    </div>

                    <div className="bazaarCard">
                        <Grid container spacing={2}>
                            <BazaarCard getAllBazars={getAllBazar} />
                        </Grid>
                    </div>
                </div>
                {/* All Plans Details */}
                <Dialog open={open} className="planPopUP" onClose={handleClose} aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className="flex items-center justify-between">
                        <p className="text-[#2E2C34] text-[18px] font-semibold pb-3">All Plan Sold</p>
                        <p className="closeBtn cursor-pointer" onClick={handleClose}></p>
                    </div>
                    <div className="bazaarButtons">
                    <div onClick={() => {
                            handleToggle1('today');
                        }}><ActionButton variant={activeTab1 === 'today' ? "primary" : "default"} title={"Today"} /></div>
                        <div onClick={() => {
                            handleToggle1('this_week');
                        }}><ActionButton variant={activeTab1 === 'this_week' ? "primary" : "default"} title={"This Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('last_week');
                        }}><ActionButton variant={activeTab1 === 'last_week' ? "primary" : "default"} title={"Last Week"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('this_month');
                        }}><ActionButton variant={activeTab1 === 'this_month' ? "primary" : "default"} title={"This Month"} />
                        </div>
                        <div onClick={() => {
                            handleToggle1('last_month');
                        }}><ActionButton variant={activeTab1 === 'last_month' ? "primary" : "default"} title={"Last Month"} />
                        </div>
                        <DatePicker render={<input type="text" readOnly className="h-[40px] cursor-pointer w-[194px] text-[#84818A] text-[14px] font-[Manrope] font-[600] outline-none border border-[#EBEAED] px-[5px]" placeholder="Select Date Range" />} value={daterangevalue1} dateSeparator=" to " format="DD/MM/YYYY" range onChange={setdaterangevalue1} />

                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack spacing={3}>
                                <DateRangePicker
                                    label="Year"
                                    value={value}
                                    onChange={handleChange}
                                    renderInput={(startProps: any, endProps: any) => (
                                        <React.Fragment>
                                            <TextField {...startProps} />
                                            <Box sx={{ mx: 2 }}> to </Box>
                                            <TextField {...endProps} />
                                        </React.Fragment>
                                    )}
                                />
                            </Stack>
                        </LocalizationProvider>
                    </div>

                    <div className="bazaarFilters pt-[20px] flex gap-3 pb-5">
                    <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"Bazaar"}
                            hint={"Select Bazaar"}
                            options={planBazzarlist}
                            selectedIds={planBazzarlist?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"State"}
                            hint={"Search"}
                            options={planStateList}
                            selectedIds={planStateList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"District"}
                            hint={"Search"}
                            options={planDisList}
                            selectedIds={planDisList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />

                        <CommonSelect
                            handleSelect={handlePlanStateChange}
                            label={"City"}
                            hint={"Search"}
                            options={planCityList}
                            selectedIds={planCityList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                        />
                    </div>

                    <div className="counterCard">
                        <BazaarCounter data={planSold} />
                    </div>

                    <div className="planList">
                        <BazaarsPlanList data={planSold?.plan_sold_data || []}/>
                    </div>

                </Dialog>
                {/* All Plans Details */}

            </DashboardLayout>
        </>
    );
};

export default Dashboard;
