import React, { useState, useEffect } from "react";
import { DashboardLayout } from "@/components/layouts";
import { useAdvertisementStyles } from "@/static/stylesheets/screens";
import { SectionHeader } from "@/components/molecules/Bazaars";
import { TbReceipt } from "react-icons/tb";
import { BiDotsVerticalRounded, BiTrash } from "react-icons/bi";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { BsEye, BsPlus, BsTrash } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AppService } from "@/service/AllApiData.service";
import { Data } from "@react-google-maps/api";
import { GridOptionButton } from "@/components/atoms/Button";
import { BiEdit } from "react-icons/bi"
import { GrEdit, GrView } from "react-icons/gr"
import moment from "moment";
import { formatIndianRupee } from "../AgentDetails/AgentReport";
import { Pagination, Tooltip } from "@mui/material";
import Loading from "@/components/LoadingCom/Loading";
import { useAuth } from "@/context/authContext";

const Advertisement = () => {
  const classes = useAdvertisementStyles();
  const [active, setactive] = useState<any>();
  const options = ["Advertisement Clients", "Ads", "Plans", "Invoices"];
  const [personName, setPersonName] = React.useState<string[]>([]);
  const [state, setstate] = React.useState<string[]>([]);
  const [AllStates, setAllStates] = React.useState<string[]>([]);
  const [Alladvertisementdata, setAlladvertisementdata] = React.useState<
    string[]
  >([]);
  const [ALlCity, setAllCity] = useState<any>([]);
  const [allDistrict, setAllDistrict] = useState<any>([]);
  const [AllClientsData, setAllClientsData] = useState<any>({});
  const [adsData, setAdsData] = useState<any>([]);
  const [bazaarData, setBazaarData] = useState<any>([]);
  const [allInvoices, setAllInvoices] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const { isAuthenticated, checkLogin, permissions } = useAuth();

  const [searchK, setSearchK] = useState("")
  console.log(Alladvertisementdata, "all");
  const [queryParams] = useSearchParams();
  const navigate = useNavigate();
  const names = [
    "Oliver Hansen",
    "Van Henry",
    "April Tucker",
    "Ralph Hubbard",
    "Omar Alexander",
    "Carlos Abbott",
    "Miriam Wagner",
    "Bradley Wilkerson",
    "Virginia Andrews",
    "Kelly Snyder",
  ];

  useEffect(() => {
    setactive(Number(queryParams.get("active") || 0))
  }, [queryParams])

  const handleChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
  };

  const handleChangestate = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setstate(typeof value === "string" ? value.split(",") : value);
  };
  const handleAdspage = () => {
    setSearchK("")
    if (active === 0) {
      navigate("/createadclient");
      // getAdsClient();
    }
    if (active === 1) {
      navigate("/addnewadvertisement");
      // getAlladsData();
    }
    if (active === 2) {
      navigate("/createadsplan");
      // getAdsPlan();
    }
  };
  const getAdsClient = async (params: any = {}) => {
    setLoading(false);
    const responseJson = await AppService.getAdsClient(params);
    setAllClientsData(responseJson.data);
    setTotalCount(responseJson.data.count);
  };
  const getTotalStates = async () => {
    const responseJson = await AppService.getTotalStates();
    setAllStates(responseJson.data);
  };
  const getAllCity = async () => {
    const responseJson = await AppService.getAllCity();
    setAllCity(responseJson?.data?.results);
  };
  const getAllDistrict = async () => {
    const responseJson = await AppService.getAllDistric();
    setAllDistrict(responseJson?.data?.results);
  };
  const getAlladsData = async (params: any = {}) => {
    const responseJson = await AppService.getAdsAllData(params);
    setAlladvertisementdata(responseJson?.data?.results);
    setTotalCount(responseJson.data.count);
    setLoading(false);
  };
  const getBazaarData = async () => {
    const responseJson = await AppService.getAllBazaar();
    setBazaarData(responseJson?.data?.results);
  };
  const getAdsPlan = async (params: any = {}) => {
    const responseJson = await AppService.getAdsPlan(params);
    setAdsData(responseJson?.data?.results);
    setTotalCount(responseJson.data.count);
    setLoading(false);
  };
  const getAdsInvoices = async (params: any = {}) => {
    const responseJson = await AppService.getAdsInvoices(params);
    setAllInvoices(responseJson?.data?.results);
    setTotalCount(responseJson.data.count);
    setLoading(false);
  };

  useEffect(() => {
    getTotalStates();
    getBazaarData();
    getAllCity();
    getAllDistrict();
  }, []);

  // useEffect(()=>{
  //   if(active === 3){
  //     setSearchK("")
  //   }
  // },[active])

  useEffect(() => {
    let params: any = {
      page: currentPage
    }
    if (searchK) {
      params = {
        ...params,
        search: searchK
      }
    }

    setTimeout(()=>{
      if (active === 0) {
        getAdsClient(params);
      }
      if (active === 1) {
        getAlladsData(params);
      }
      if (active === 2) {
        getAdsPlan(params);
      }
      if (active === 3) {
        getAdsInvoices(params);
      }
    },600)
  }, [searchK, active, currentPage])

  console.log(adsData, "adsData");

  return (
    <>
      <DashboardLayout>
        {
          loading ? <Loading /> :
            <div className={classes.root}>
              <SectionHeader />
              <div className="container">
                <div className="flex items-center justify-between">
                  <div className="commonTitle">Ads</div>
                  <div className="flex gap-2 search-div">
                    <div className="relative" style={{ width: "500px", marginRight: "10px" }}>
                      <input
                        type="text"
                        onChange={(e) => setSearchK(e.target.value)}
                        placeholder="Search..."
                        className="w-full py-[11px] pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white"
                        style={{ fontSize: "14px", fontWeight: 500, fontFamily: "Manrope", color: "#84818A", paddingLeft: "16px" }}
                      // onKeyDown={(e:any)=>e.key === 'Enter' && getWholesellers()}
                      />
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        style={{ right: "16px", color: "#504F54", width: "17.49px", height: "17.49px" }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                        />
                      </svg>
                    </div>
                    <Tooltip title={!permissions.includes("ads.create") ? "Not Authorized" : ""}>
                    <button
                      className={`w-[200px] outline-none bg-[#FF6652] h-[48px] text-[14px] rounded text-center font-[Manrope] font-[600] text-[#FFFFFF] flex items-center justify-center gap-1 ${active !== 3 ? "flex" : "hidden"
                        }`}
                      onClick={() => {
                        if(!permissions.includes("ads.create")){
                          return
                        }

                        handleAdspage();
                      }}
                    >
                      <span className="text-[25px] text-white">
                        <BsPlus />
                      </span>{" "}
                      {active === 1
                        ? "Create New Ad"
                        : active === 2
                          ? "Create New Plan"
                          : "Create New Client"}
                    </button>
                    </Tooltip>
                  </div>
                </div>
                <div className="mt-[16px]">
                  <ul className="list-none flex gap-4 m-0 p-0">
                    {options.map((item: any, index: any) => {
                      return (
                        <li
                          className={`h-[34px] ${index === active
                            ? "border-b-2 border-[#5542F6]"
                            : "border-b-2 border-transparent"
                            }`}
                        >
                          <button
                            className={`w-fit text-[14px] text-center px-[12px] font-[Manrope] font-[600] ${index === active ? "text-[#2E2C34]" : "text-[#84818A]"
                              }`}
                            onClick={() => {
                              setactive(index);
                              setCurrentPage(1);
                              setSearchK("")
                            }}
                          >
                            {item}
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {active === 0 && (
                  <div>
                    <table className="w-full mt-[35px]">
                      <thead className="border-b border-[#EBEAED]">
                        <tr>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            SL No.
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Client Name
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Mobile Number
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Total Ads
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Active Ads
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Total Invoices
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Pending Invoices
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-right text-[14px] font-[Manrope] font-[600]">
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {AllClientsData?.results?.map((item: any, index: number) => (
                          <tr className="h-[64px] items-center border-b border-[#EBEAED]">
                            <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                              {index + 1 + ((currentPage - 1) * 10)}
                            </td>
                            <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                              {item.client_name}
                            </td>
                            <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                              {item.phoneNumber}
                            </td>
                            <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                              {item.ads_count}
                            </td>
                            <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                              {item.active_ads}
                            </td>
                            <td>
                              <div className="flex gap-[10px] items-center">
                                <p className="text-[rgb(46,44,52)] text-left text-[14px] font-[Manrope] font-[500]" onClick={()=>{
                                  setactive(3);
                                  // setSearchK()
                                  }}>
                                  {item.related_invoices > 0
                                    ? item.related_invoices
                                    : "-"}
                                </p>
                                {item.related_invoices > 0 && (
                                  <span className="text-[#5542F6]">
                                    <TbReceipt />
                                  </span>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="flex gap-[10px] items-center">
                                <p className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]" onClick={()=>{
                                  setactive(3);
                                  setSearchK(item.client_name)
                                  }}>
                                  {item.pending_invoices > 0
                                    ? item.pending_invoices
                                    : "-"}
                                </p>
                                {item.pending_invoices > 0 && (
                                  <span className="text-[#5542F6]">
                                    <TbReceipt />
                                  </span>
                                )}
                              </div>
                            </td>
                            <td className="py-4 px-6 tableData cursor-pointer" style={{ justifyContent: "end", display: "flex", paddingRight: 0 }}>
                              {
                                <GridOptionButton
                                  icon={"vertical-options"}
                                  menus={[
                                    {
                                      label: (
                                        <>
                                          <span className="icon">
                                            {/* <img src={complete} alt="dots" /> */}
                                            <BsEye />
                                          </span>
                                          <p onClick={() => navigate(`/adclient/${item.id}/view`)}>
                                            View Ad
                                          </p>

                                        </>
                                      ),
                                    },
                                    {
                                      label: (
                                        <Tooltip title={!permissions.includes("ads.update") ? "Not Authorized" : ""}>
                                        <span style={{display:'flex',alignItems:'center'}}>
                                          <span className="icon" style={{marginRight:'15px'}}>
                                            {/* <img src={complete} alt="dots" /> */}
                                            <BiEdit />
                                          </span>
                                          <p onClick={() =>{
                                            if(!permissions.includes("ads.update")){
                                              return
                                            }
                                            navigate(`/adclient/${item.id}/edit`)
                                            
                                            }}>
                                            Edit Ad
                                          </p>

                                        </span>
                                        </Tooltip>
                                      ),
                                    },

                                  ]}
                                />
                              }

                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <div
                      className="flex items-center justify-between"
                      style={{ display: "flex", marginLeft: 0 }}
                    >
                      <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={currentPage}
                        onChange={(e: any, value: any) => { setCurrentPage(value); setLoading(true); }}
                      />
                    </div>
                  </div>
                )}

                {active === 1 && (
                  <div>
                    <table className="w-full mt-[35px]">
                      <thead className="border-b border-[#EBEAED]">
                        <tr>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            SL No.
                          </th>

                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Ads created date
                          </th>

                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Clients Name
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Mobile Number
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Plan Name
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            State
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            District
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            City
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Duration
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Time Slot
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Price
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-right text-[14px] font-[Manrope] font-[600]">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {Alladvertisementdata?.map((itm: any, index: number) => {
                          const intersection = AllStates?.filter((it: any) => {
                            return itm?.select_state?.indexOf(it?.id) > -1;
                          });
                          return (
                            <tr className="h-[64px] items-center border-b border-[#EBEAED]">
                              {/* <td>
                            <div className="flex gap-[14px] items-center">
                              <div className="w-[36px] h-[36px] rounded-[2px]">
                                <img
                                  src={itm?.media}
                                  alt=""
                                  className="object-cover w-full h-full rounded-[2px]"
                                />
                              </div>
                              <p className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.ad_title}
                              </p>
                            </div>
                          </td> */}
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {index + 1 + ((currentPage - 1) * 10)}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {moment(itm?.created_at).format("DD-MM-YYYY hh:mm:ss A")}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.client_data?.client_name || itm?.wholeseller_data?.wholeseller_firm_name}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.client_data?.phoneNumber || itm?.wholeseller_data?.wholeseller_number}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.choose_plan_data?.plan_name}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.choose_plan_data?.state_data?.map((item: any) => item.state)?.join(" ,")}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.choose_plan_data?.district_data?.map((item: any) => item.district)?.join(" ,")}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.choose_plan_data?.city_data?.map((item: any) => item.city)?.join(" ,")}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {itm?.choose_plan_data?.plan_periods_in_days} {" "} Days
                              </td>
                              <td>
                                <div className="flex gap-1 flex-col justify-center">
                                  <div className="w-[103px]  h-[29px] border flex justify-center items-center border-[#E1E1E1] text-[#2E2C34] text-left text-[14px] rounded-full font-[Manrope] font-[500]">
                                    {itm?.choose_plan_data?.time_slot}
                                  </div>
                                </div>
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {formatIndianRupee(itm?.choose_plan_data?.price)}
                              </td>
                              <td className="py-4 px-6 tableData cursor-pointer" style={{ justifyContent: "end", display: "flex", paddingRight: 0 }}>
                                {
                                  <GridOptionButton
                                    icon={"vertical-options"}
                                    menus={[
                                      {
                                        label: (
                                          <>
                                            <span className="icon">
                                              {/* <img src={complete} alt="dots" /> */}
                                              <BsEye />
                                            </span>
                                            <p onClick={() => navigate(`/advertisement/${itm.id}/view`)}>
                                              View Ad
                                            </p>

                                          </>
                                        ),
                                      },
                                      {
                                        label: (
                                            <Tooltip title={!permissions.includes("ads.update") ? "Not Authorized" : ""}>
                                            <div
                                              onClick={() => {
                                              if (!permissions.includes("ads.update")) {
                                                return;
                                              }
                                              navigate(`/advertisement/${itm.id}/edit`);
                                              }}
                                              style={{ display: "flex", alignItems: "center", cursor: permissions.includes("ads.update") ? "pointer" : "not-allowed" }}
                                            >
                                              <span className="icon" style={{marginRight:'15px'}}>
                                              <BiEdit />
                                              </span>
                                              <p>Edit Ad</p>
                                            </div>
                                            </Tooltip>
                                        ),
                                      },
                                      //   {
                                      //     label: (
                                      //         <>
                                      //             <span className="icon">
                                      //                 {/* <img src={complete} alt="dots" /> */}
                                      //                 <BiTrash/>
                                      //             </span>
                                      //             <p onClick={() => navigate(`/agentkyc/${itm.id}`)}>
                                      //                 Delete Ad 
                                      //             </p>

                                      //         </>
                                      //     ),
                                      // },

                                    ]}
                                  />
                                }

                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div
                      className="flex items-center justify-between"
                      style={{ display: "flex", marginLeft: 0 }}
                    >
                      <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={currentPage}
                        onChange={(e: any, value: any) => { setCurrentPage(value); setLoading(true); }}
                      />
                    </div>
                  </div>
                )}
                {active === 2 && (
                  <div>
                    <table className="w-full mt-[35px]">
                      <thead className="border-b border-[#EBEAED]">
                        <tr>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Plan Name
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Bazaar
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            State
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            District
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            City
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Duration
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Plan Time Slot
                          </th>
                          <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                            Price
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {adsData.map((item: any) => {
                          const filter = bazaarData.find(
                            (data: any) => data.id === item.bazaar
                          );

                          return (
                            <tr
                              className="h-[64px] items-center border-b border-[#EBEAED]"
                              key={item.id}
                            >
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {item?.plan_name}
                              </td>
                              <td>
                                <p className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {filter?.bazaar_name}
                                </p>
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                              {item.state_data.map((item: any, index:any) => (
                                  <p 
                                  className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]"
                                >
                                  {item.state}
                                </p>
                                ))}
                              </td>
                              <td>
                              {item.district_data.map((item: any, index:any) => (
                                  <p 
                                  className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]"
                                >
                                  {item.district}
                                </p>
                                ))}
                              </td>
                              <td>
                                {item.city_data.map((item: any, index:any) => (
                                  <p 
                                  className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]"
                                >
                                  {item.city}
                                </p>
                                ))}
                              </td>
                              <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                {item?.plan_periods_in_days} {" "} Days
                              </td>
                              <td>
                                <div className="flex gap-1 flex-col justify-center">
                                  <div className="w-[103px]  h-[29px] border flex justify-center items-center border-[#E1E1E1] text-[#2E2C34] text-left text-[14px] rounded-full font-[Manrope] font-[500]">
                                    {item?.time_slot}
                                  </div>
                                </div>
                              </td>
                              <td>
                                <p  className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                   {formatIndianRupee(item?.price)}
                                </p>
                              </td>
                              <td className="py-4 px-6 tableData cursor-pointer" style={{ justifyContent: "end", display: "flex", paddingRight: 0 }}>
                                {
                                  <GridOptionButton
                                    icon={"vertical-options"}
                                    menus={[
                                      {
                                        label: (
                                          <>
                                            <span className="icon">
                                              {/* <img src={complete} alt="dots" /> */}
                                              <BsEye />
                                            </span>
                                            <p onClick={() => navigate(`/adsplan/${item.id}/view`)}>
                                              View Ad
                                            </p>

                                          </>
                                        ),
                                      },
                                      {
                                        label: (
                                          <Tooltip title={!permissions.includes("ads.update") ? "Not Authorized" : ""}>
                                            <div
                                              onClick={() => {
                                                if (!permissions.includes("ads.update")) {
                                                  return
                                                }
                                                navigate(`/adsplan/${item.id}/edit`);
                                              }}
                                              style={{ display: "flex", alignItems: "center", cursor: permissions.includes("ads.update") ? "pointer" : "not-allowed" }}
                                            >
                                              <span className="icon" style={{marginRight:'15px'}}>
                                                <BiEdit />
                                              </span>
                                              <p>Edit Ad</p>
                                            </div>
                                          </Tooltip>
                                        ),
                                      },
                                      //   {
                                      //     label: (
                                      //         <>
                                      //             <span className="icon">
                                      //                 {/* <img src={complete} alt="dots" /> */}
                                      //                 <BiTrash/>
                                      //             </span>
                                      //             <p onClick={() => navigate(`/agentkyc/${itm.id}`)}>
                                      //                 Delete Ad 
                                      //             </p>

                                      //         </>
                                      //     ),
                                      // },

                                    ]}
                                  />
                                }

                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    <div
                      className="flex items-center justify-between"
                      style={{ display: "flex", marginLeft: 0 }}
                    >
                      <Pagination
                        count={Math.ceil(totalCount / 10)}
                        page={currentPage}
                        onChange={(e: any, value: any) => { setCurrentPage(value); setLoading(true); }}
                      />
                    </div>
                  </div>
                )}
                {active === 3 && (
                  <div className="mt-[45px]">
                    {/* <div className="flex gap-[11px] items-center">
                  <button className="w-[156px] h-[36px] rounded text-white bg-[#4E2FA9] flex justify-center items-center text-[14px] font-[Manrope] font-[600]">
                    Awaiting Payment
                  </button>
                  <FormControl>
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      sx={{
                        textAlign: "center",
                        color: "#84818A",
                        fontSize: "14px",
                        fontFamily: "Manrope",
                        fontWeight: 600,
                      }}
                    >
                      Bill Date
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      sx={{
                        width: 109,
                        height: 36,
                        textAlign: "center",
                        color: "#84818A",
                        fontSize: "14px",
                        fontFamily: "Manrope",
                        fontWeight: 600,
                      }}
                      value={personName}
                      onChange={handleChange}
                      label="Bill Date"
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {names.map((name) => (
                        <MenuItem key={name} value={name}>
                          <Checkbox checked={personName.indexOf(name) > -1} />
                          <ListItemText primary={name} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <FormControl>
                    <InputLabel
                      id="demo-multiple-checkbox-label"
                      sx={{
                        textAlign: "center",
                        color: "#84818A",
                        fontSize: "14px",
                        fontFamily: "Manrope",
                        fontWeight: 600,
                      }}
                    >
                      State
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      multiple
                      sx={{
                        width: 93,
                        height: 36,
                        textAlign: "center",
                        color: "#84818A",
                        fontSize: "14px",
                        fontFamily: "Manrope",
                        fontWeight: 600,
                      }}
                      value={state}
                      onChange={handleChangestate}
                      label="State"
                      renderValue={(selected) => {
                        var data: any = [];
                        AllStates.map((item: any) => {
                          if (selected.includes(item?.id)) {
                            data.push(item.state);
                          }
                        });
                        return data.join(", ");
                      }}
                    >
                      {AllStates.map((item: any) => (
                        <MenuItem key={item?.id} value={item?.id}>
                          <Checkbox checked={state.indexOf(item?.id) > -1} />
                          <ListItemText primary={item?.state} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <button className="w-[100px] rounded px-[16px] py-[8px] text-[#FC3400] text-center text-[14px] font-[Manrope] font-[600]">
                    Reset
                  </button>
                </div> */}
                    <div>
                      <table className="w-full mt-[30px]">
                        <thead className="border-b border-[#EBEAED]">
                          <tr>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              SL
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Client Name
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Mobile Number
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Ads name
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Plan Name
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Bill Date
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Bill Amount
                            </th>
                            <th className="text-[#84818A] pb-[11px] text-left text-[14px] font-[Manrope] font-[600]">
                              Status of Payment
                            </th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            allInvoices.map((item: any, index: any) => (
                              <tr className="h-[64px] items-center border-b border-[#EBEAED]">
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {index + 1 + ((currentPage - 1) * 10)}
                                </td>
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {item?.client_data?.client_name || item?.ad_data?.wholeseller_data?.wholeseller_firm_name}
                                </td>
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {item?.client_data?.phoneNumber || item?.ad_data?.wholeseller_data?.wholeseller_number}
                                </td>
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {item?.ad_data?.id}
                                </td>
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {item?.ad_data?.choose_plan_data?.plan_name}
                                </td>
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {moment(item?.plan_data?.created_for).format("DD-MM-YYYY hh:mm A")}
                                </td>
                                <td className="text-[#2E2C34] text-left text-[14px] font-[Manrope] font-[500]">
                                  {formatIndianRupee(item?.bill_amount)}
                                </td>
                                <td>
                                  <div className="flex justify-between items-center">
                                    {
                                      item?.status === "Awaiting Payment" ?
                                        <p onClick={() => navigate(`/adinvoice/${item?.id}/pay`)} className="text-[#4E2FA9] underline text-left cursor-pointer text-[14px] font-[Manrope] font-[500]">
                                          Pay Now
                                        </p> :
                                        <p className="text-[#FF6652] text-left text-[14px] font-[Manrope] font-[500]">
                                          {item?.status}
                                        </p>
                                    }
                                    <div className="flex gap-[34px]">
                                      <button
                                        className="text-[#4E2FA9]"
                                        onClick={() => {
                                          navigate("/invoiceview/"+item?.id);
                                        }}
                                      >
                                        <TbReceipt />
                                      </button>

                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                      <div
                        className="flex items-center justify-between"
                        style={{ display: "flex", marginLeft: 0 }}
                      >
                        <Pagination
                          count={Math.ceil(totalCount / 10)}
                          page={currentPage}
                          onChange={(e: any, value: any) => { setCurrentPage(value); setLoading(true); }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
        }
      </DashboardLayout>
    </>
  );
};

export default Advertisement;
