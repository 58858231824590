import React, { useEffect, useState } from "react";
import { useAuthenticationLayoutStyles } from "@/static/stylesheets/layouts";


interface LoginFormProps {
  onSuccess?(code:string): any;
  inputvalues?: any;
}

const LoginForm: React.FC<LoginFormProps> = (props : any) => {
  const classes = useAuthenticationLayoutStyles();
  const [phonenumber , setPhonenumber] = useState('')
  const [errormeg,setErrormeg] = useState(false);
  const [countryCode,setCountryCode] = useState("+91");

  useEffect(()=>{
    props.inputvalues(phonenumber)
  },[phonenumber])


  const Numberfuction = (e:any) =>{
    let pattern = /^(\d{0,10})$/;
    if(e.target.value.match(pattern)){
      setPhonenumber(e.target.value)
    }
  }

  return (
    <>
      <div className={classes.loginTitle}>Admin Login</div>
      <div className={classes.hint}>
        Enter your registered mobile number to get OTP
      </div>

      <div className={classes.phoneTextInput}>
        <select value={countryCode} onChange={(e)=>setCountryCode(e.target.value)}>
          <option value={"+91"}>+91</option>
          <option value={"+88"}>+88</option>
        </select>
        <input placeholder={"Mobile number"} type={"text"} value={phonenumber} autoComplete="on" onChange={(e : any)=>Numberfuction(e)}/>
      </div>

      <div className={classes.submitButtonContainer}>
        <button className={classes.submitButton} onClick={()=>{props?.onSuccess(countryCode)}}>
          Sign In
        </button>
      </div>
    </>
  );
};

export { LoginForm };
