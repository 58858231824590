import React, { useEffect, useState } from "react";
import { usecityBuisnessStyle } from "@/static/stylesheets/molecules";
import { AppService } from "@/service/AllApiData.service";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";

const CityBuisness = (props: any) => {
  const { data } = props;
  const classes = usecityBuisnessStyle();
 
  return (
    <div className={classes.root} style={{ overflowX: "auto" }}>
      <table style={{ width: "100%" }}>
        <thead>
          <tr>
            <th>Cities</th>
            <th>ORDERS</th>
            <th style={{ textAlign: "end", paddingRight: 0 }}>SALES</th>
          </tr>
        </thead>
        <tbody>
          {data[0]?.cities_name?.map((item: any,index:number) => {
            // const cityname = Allcities.find((it: any) => it.id === item.cities)
            return (< tr key={index}>
              <td>{item?.city}</td>
              <td>{item?.orders}</td>
              <td style={{ textAlign: "end", paddingRight: 0 }}>{formatIndianRupee(item?.sales)}</td>
            </tr>)
          })}
        </tbody>
      </table>
    </div >
  );
};

export { CityBuisness };