import React, { useEffect, useState } from "react";
import { useBazaarCounterStyles } from "@/static/stylesheets/molecules";
import { AppService } from "@/service/AllApiData.service";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";


interface bazaarCounterProps{
  data:any
}

const BazaarCounter : React.FC<bazaarCounterProps> = ({data}) => {


  const classes = useBazaarCounterStyles();
  return (
    <div className={classes.root}>
      <div className={classes.wholesellerCard}>
        <div>
          <div className="wholesellerItem">
            <p className="headTitle">Total Plans</p>
            <p className="wholesellerCount">{data?.plan}</p>
          </div>
        </div>

        <div className="borderItem">
          <div className="wholesellerItem">
            <p className="headTitle">Total Subscribers</p>
            <p className="subscriberCount">{data?.subscriber}</p>
          </div>
        </div>

        <div className="borderItem">
          <div className="wholesellerItem">
            <p className="headTitle">Revenue Generated</p>
            <p className="revenewCount">{formatIndianRupee(data?.revenue || 0)}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export { BazaarCounter };
