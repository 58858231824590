import React from "react";
import { useTopwholesellerStyle } from "@/static/stylesheets/molecules";
import LogoEcBazaar from "@/static/images/placeholder.jpg";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";

const TopWholeseler = (props:any) => {
  const { list=[],viewAll=false } = props;
  const classes = useTopwholesellerStyle();
  const data = [1, 2, 3];

  return (
    <div className={classes.root} style={{ overflowX: "auto" }}>
      <table className="topWholesalerTable">
        <tr>
          <th className="planTitle">Name</th>
          <th className="bazaarTitle">City</th>
          <th className="stateTitle">Orders</th>
          <th className="districtTitle" style={{ textAlign: "end", paddingRight: 0 }}>Sales</th>
        </tr>

        {
        viewAll ?
        list[0]?.name?.map((name: any, index: number) => (
          <tr>
            <td>
              <div className="brandData" style={{ alignItems: "center" }}>
                <img className="brandLogo" src={list[0].picture[index]} alt={"Logo"} />
                <p>{name}</p>
              </div>
            </td>
            <td>{list[0].city[index]}</td>
            <td>{list[0].orders[index]}</td>
            <td style={{ textAlign: "end", paddingRight: 0 }}>₹{formatIndianRupee(list[0].sales[index])}</td>
          </tr>
        ))
        :
        list[0]?.name?.slice(0,5)?.map((name: any, index: number) => (
          <tr>
            <td>
              <div className="brandData" style={{ alignItems: "center" }}>
                <img className="brandLogo" src={list[0].picture[index]} alt={"Logo"} />
                <p>{name}</p>
              </div>
            </td>
            <td>{list[0].city[index]}</td>
            <td>{list[0].orders[index]}</td>
            <td style={{ textAlign: "end", paddingRight: 0 }}>{formatIndianRupee(list[0].sales[index])}</td>
          </tr>
        ))
      }
      </table>
    </div>
  );
};

export { TopWholeseler };
