import React, { useEffect, useState } from "react";
import { useBazaarplanListStyles } from "@/static/stylesheets/molecules";
import { AppService } from "@/service/AllApiData.service";
import { Pagination } from "@mui/lab";
import { formatIndianRupee } from "@/screens/AgentDetails/AgentReport";

const BazaarsPlanList = (props: any) => {

  const classes = useBazaarplanListStyles();
  const data = [1, 2, 3, 4, 5, 6, 7];
  const [getPlans, setTotalPlans] = useState<any>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [bazaars, setBazaars] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [states, setStates] = useState<any>([]);
  const [cities, setCities] = useState<any>([]);
  const [count, setCount] = useState(1);
  const [plans,setPlans] = useState<any>([]);

  const getAllDis = async () => {
    const responseJson = await AppService.getTotalDistrict()
    setDistricts(responseJson.data);
  };
  const getAllState = async () => {
    const responseJson = await AppService.getTotalCity()
    setStates(responseJson.data);
  };
  const getAllCity = async () => {
    const responseJson = await AppService.getAllCity()
    setCities(responseJson.data.results);
  };
  const getAllBazaarData = async () => {
    const responseJson = await AppService.getAllBazaar()
    setBazaars(responseJson.data.results);
  };


  useEffect(()=>{
    getAllBazaarData();
    getAllState();
    getAllDis();
    getAllCity();
  },[])
  
  useEffect(()=>{
    console.log(bazaars,states,cities,districts)
  },[bazaars,states,cities,districts])
  
  const ITEMS_PER_PAGE = 4;
  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
  };
  
  


  const getAllLists = async () => {
    const responseJson = await AppService.getAllPlansData();
    // console.log(responseJson.data.bazaar);
    setTotalPlans(responseJson.data.results);

    // totalWholesellers(responseJson);

  };

  // const totalBazaar = (responseJson:any) =>{
  //   let sum = responseJson.data = (datas:any)=> {
  //     return datas.bazaars
  //   };
  //   console.log(sum);
  //   setTotalBazaar(sum);
  // }

  // const totalWholesellers = (responseJson:any) =>{
  //   let sum = responseJson.data.results.reduce(function(prev: number, current:any) {
  //     return prev + +current.wholesellers
  //   }, 0);
  //   console.log(sum);
  //   setWholesellers(sum);
  // }

  useEffect(()=>{
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  
    const endIndex = startIndex + ITEMS_PER_PAGE;
    const currentItems = getPlans.slice(startIndex, endIndex);
    
    console.log("logger123...",props.data,currentItems  )
    setPlans(currentItems);
  },[currentPage,getPlans])

  useEffect(() => {
    // getAllLists();
   
    setTotalPlans(props.data);
  }, [props.data]);

  return (
    <div className={classes.root}>
      <table className="plan-table">
        <tr>
          <th>Plan Name</th>
          <th>Bazaar</th>
          <th>State</th>
          <th>District</th>
          <th>City</th>
          <th className="duration">Duration</th>
          <th>Plan Price</th>
          <th>
            Subscribers
            <table className="inside-table">
              <tr>
                <th>Active</th>
                <th>Expired</th>
                {/* <th>Deactivated</th> */}
              </tr>
            </table>
          </th>
          <th>Revenue
            <div>
              Generated
            </div>
          </th>
        </tr>

        {plans?.map((item: any) => (
          <tr>
            <td className="plan-name">{item.plan_name}</td>
            {/* <td>{item.bazaar?.map((bazaar:any)=>bazaars.find((ele:any)=>ele.id === bazaar)?.bazaar_name)?.join(", ")}</td> */}
            <td>{item.bazaar?.map((bazaar:any)=>bazaar?.bazaar_name)?.join(", ")}</td>
            <td className="state">
              {/* <p className="w-[50px] truncate">{item.state?.map((state:any)=>states.find((ele:any)=>ele.id === state)?.state)?.join(", ")}</p> */}
              <p className="w-[50px] truncate">{item.state?.map((ele:any)=>ele.state).join(", ")}</p>
            </td>
            <td className="has-details">
              {/* <p className="w-[50px] truncate">{item.district?.map((item:any)=>districts.find((ele:any)=>ele.id === item)?.district)?.join(", ")}</p> */}
              <p className="w-[50px] truncate">{item.district?.map((item:any)=> item.district)?.join(", ")}</p>
            </td>
            <td className="">
              {/* <p className="w-[50px] truncate">{item.city?.map((item:any)=>cities.find((ele:any)=>ele.id === item)?.city)?.join(", ")}</p> */}
              <p className="w-[50px] truncate">{item.city?.map((item:any)=>item.city)?.join(", ")}</p>
            </td>
            <td className="duration">{item.plan_periods_in_days} Days</td>
            <td className="price">Rs. {item.amount}</td>
            <td>
              <table className="inside-table">
                <tr>
                  <td className="activeTitle">{item.subscriber_active}</td>
                  <td className="expiredTitle">{item.subscriber_expired}</td>
                  {/* <td className="revenueTitle">{item.subscribers_deactivated}</td> */}
                </tr>
              </table>
            </td>
            <td className="pr-0">
              <div className="flex items-center gap-3 whitespace-nowrap">
                <div>
                  <div> {formatIndianRupee(item.revenue_generated)}</div>
                  {/* <div className="w-full bg-[#EBEAED] rounded-sm h-1 mt-1">
                    <div
                      className="bg-[#5542F6] h-1 rounded-  "
                      style={{ width: "45%" }}
                    ></div>
                  </div> */}
                </div>
                {/* <div>16.30%</div> */}
              </div>
            </td>
          </tr>
        ))}
      </table>
      <div className="flex items-center justify-between mt-4">
        <Pagination count={Math.ceil(getPlans.length / ITEMS_PER_PAGE)} page={currentPage} onChange={handlePageChange} />
        <div className="text-[#84818A] text-sm font-medium">Show <span>{plans.length}</span> from {getPlans.length} products</div>
      </div>
    </div>

  );
};

export { BazaarsPlanList };
