import React, { useEffect, useState } from "react";
import { DashboardLayout } from "@/components/layouts";
import { AddButton, GridOptionButton } from "@/components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { usAgentStyles } from "@/static/stylesheets/screens/agentStyle";
import { ActionButton } from "@/components/atoms/Button/ActionButton";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  FormControl,
  FormControlLabel,
  Pagination,
  Radio,
  RadioGroup,
  TextField,
  Tooltip,
} from "@mui/material";
import complete from "@/static/icons/complete.svg";
import icPhoneHandle from "@/static/icons/phoneHandle.svg";
import fill from "@/static/icons/fill.svg";
import calendar from "@/static/icons/calendar.svg";
import deleteagent from "@/static/icons/delete-agent.svg";
import { AppService } from "@/service/AllApiData.service";
import contact from "@/static/icons/contact-phone.svg";
import { FaMapMarkerAlt } from "react-icons/fa";
import { HiPhone } from "react-icons/hi";
import { BsFileEarmarkPlus } from "react-icons/bs";
import { Alert, AlertError } from "@/alert/Alert";
import CommonSelectElectronicBazaar from "@/components/atoms/CommonSelectElectronicBazaar/CommonSelectElectronicBazaar";
import { ExpandMore, Preview } from "@mui/icons-material";
import Loading from "../../components/LoadingCom/Loading.jsx";
import { Switch } from "@/components/atoms/Switch";
import axios from "axios";
import { useAuth } from "../../context/authContext";
// import { toast } from "react-toastify";

async function convertImageTobS4(imgUrl: string) {
  const urlBase = imgUrl.endsWith("png")
    ? "data:image/png;base64,"
    : "data:image/jpg;base64,";
  const imageToBase64 = require("image-to-base64/browser.js");
  let response = await imageToBase64(imgUrl);
  return urlBase + response;
}
const Agent = (props: any) => {
  const classes = usAgentStyles();
  const navigate = useNavigate();
  const [addRejectModalOpen, setAddRejectModalOpen] = useState(false);
  const [addCommisionModalOpen, setAddCommitionModalOpen] = useState(false);
  const [getAllAgent, setGetAllAgent] = useState<any>([]);
  const [filterallAgent, setfilterallAgent] = useState<any>([]);
  const [filterAllAgentM, setfilterAllAgentM] = useState<any>([]);
  const [totalCount, SetTotalCount] = useState<number>(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [editFormData, setEditFormData] = useState<any>({});
  const [manageCommissionData, setManageCommissionData] = useState<any>({});
  const [allDis, setAllDis] = useState<any>([]);
  const [AllState, setAllState] = useState<any>([]);
  const [AllCity, setAllCity] = useState<any>([]);
  const [AllBazaarData, setAllBazaarData] = useState<any>([]);
  const [bazaarList, setBazaarList] = useState<any>([]);
  const [stateList, setstateList] = useState<any>([]);
  const [disList, setdisList] = useState<any>([]);
  const [cityList, setcityList] = useState<any>([]);
  const [Wholesellerlist, setWholesellerlist] = useState<any>([]);
  const [AllAgentType, setAllAgentType] = useState<any>([]);
  const [agentTypeList, setagentTypeList] = useState<any>([]);
  const [AllActiveInactive, setAllActiveInactive] = useState<any>([]);
  const [activeInactiveList, setactiveInactiveList] = useState<any>([
    {
      label: "ENABLE",
      value: 1,
    },
    {
      label: "DISABLE",
      value: 0,
    },
  ]);
  const [Allstatus, setAllstatus] = useState<any>([]);
  const [statusList, setstatusList] = useState<any>([
    {
      label: "CREATED",
      value: "CREATED",
    },
    {
      label: "PENDING",
      value: "PENDING",
    },
    {
      label: "KYCREJECTED",
      value: "KYCREJECTED",
    },
    {
      label: "KYCAPPROVED",
      value: "KYCAPPROVED",
    },
  ]);
  const [searchK, setSearchK] = useState("");
  const [isshowmore, setisshowmore] = useState(false);
  const [AgentImage, setAgentImage] = useState<any>("");
  const [loading, setloading] = useState(true);
  const [agentId, setAgentId] = useState(0);
  const [filterData, setFilterData] = useState<any>({
    city_id: [],
    state_id: [],
    district_id: [],
    agent_type: [],
    agent_status: [],
    agent_active: [],
  });
  const [rejectReason, setRejectReason] = useState<any>("");
  const [cancelTokenSource, setCancelTokenSource] = useState();
  const [filters, setFilters] = useState(false);

  console.log("AllBazaarData", AllBazaarData);

  const { isAuthenticated, checkLogin, permissions } = useAuth();
  const getAgents = (loader = true) => {
    const bazaarIds = bazaarList
      .filter((item: any) => item.status === true)
      .map((item: any) => item.value);
    const stateIds = stateList
      .filter((item: any) => item.status === true)
      .map((item: any) => item.value);
    const districtIds = disList
      .filter((item: any) => item.status === true)
      .map((item: any) => item.value);
    const cityIds = cityList
      .filter((item: any) => item.status === true)
      .map((item: any) => item.value);
    const statusIds = statusList
      .filter((item: any) => item.status === true)
      .map((item: any) => item.value);
    const activeIds = activeInactiveList
      .filter((item: any) => item.status === true)
      .map((item: any) => item.value);

    let params = {
      page: currentPage,
    } as any;
    if (bazaarIds.length) {
      params = {
        ...params,
        agent_bazaar: bazaarIds.join(","),
      };
    }

    if (stateIds.length) {
      params = {
        ...params,
        agent_state: stateIds.join(","),
      };
    }

    if (districtIds.length) {
      params = {
        ...params,
        agent_district: districtIds.join(","),
      };
    }

    if (cityIds.length) {
      params = {
        ...params,
        agent_city: cityIds.join(","),
      };
    }

    if (statusIds.length) {
      params = {
        ...params,
        agent_status: statusIds.join(","),
      };
    }

    if (activeIds.length == 1) {
      params = {
        ...params,
        agent_active: activeIds.join(","),
      };
    }

    if (searchK) {
      params = {
        ...params,
        search: searchK,
      };
    }

    getAllLists(params, loader);
  };

  useEffect(() => {
    getAgents(false);
  }, [searchK]);

  useEffect(() => {
    // setCurrentPage(1);

    // const bazaarIds = bazaarList.filter((item: any) => item.status === true).map((item: any) => item.value);
    // const stateIds = stateList.filter((item: any) => item.status === true).map((item: any) => item.value);
    // const districtIds = disList.filter((item: any) => item.status === true).map((item: any) => item.value);
    // const cityIds = cityList.filter((item: any) => item.status === true).map((item: any) => item.value);
    // const statusIds = statusList.filter((item: any) => item.status === true).map((item: any) => item.value);
    // const activeIds = activeInactiveList.filter((item: any) => item.status === true).map((item: any) => item.label);

    // if (bazaarIds.length || stateIds.length || districtIds.length || cityIds.length || statusIds.length || activeIds.length) {
    // }
    if (filters) {
      getAgents();
    }
  }, [
    bazaarList,
    stateList,
    disList,
    cityList,
    statusList,
    activeInactiveList,
    currentPage,
  ]);

  const handlePageChange = (event: any, value: any) => {
    setFilters(true);
    setCurrentPage(value);
  };

  // useEffect(() => {
  //     getAgents();
  // }, [currentPage])

  const getAllLists = async (params: any, loader = true) => {
    if (loader) {
      setloading(true);
    }

    // Create a cancellation token source
    let source: any = cancelTokenSource;

    // Cancel previous request (if any)
    if (source) {
      source.cancel("Previous request cancelled");
    }

    const CancelToken = axios.CancelToken;
    source = CancelToken.source();
    setCancelTokenSource(source);

    try {
      const responseJson = await AppService.getAgentByfilterView(
        params,
        source
      );
      if (responseJson.data.results) {
        setloading(false);
      }
      setGetAllAgent(responseJson.data.results);
      SetTotalCount(responseJson.data.count);
      console.log("all agents list===>", responseJson);
    } catch (err: any) {}
  };

  const [selectedTab, setSelectedTab] = useState<"PERPLAN" | "PERCUSTOMER">(
    "PERCUSTOMER"
  );

  console.log("selectedTab", selectedTab);

  const handleModalBackdrop = props.handleModalBackdrop;
  const useClickOutside = (
    ref: React.RefObject<HTMLElement>,
    onClickOutside: () => void
  ) => {
    useEffect(() => {
      const handleClickOutside = (event: MouseEvent) => {
        if (ref.current && !ref.current.contains(event.target as Node)) {
          onClickOutside();
        }
      };
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, onClickOutside]);
  };
  // const viewkycRef = useDetectClickOutside({ onTriggered: closeViewKycModal });
  const viewproductRef = React.useRef(null);
  useClickOutside(viewproductRef, () => setisshowmore(false));

  useEffect(() => {
    if (isshowmore) {
      handleModalBackdrop(true);
    } else if (!isshowmore) {
      handleModalBackdrop(false);
    }
  }, [isshowmore]);

  // useEffect(() => {
  //     setTimeout(() => {
  //         getAllLists(1);
  //     }, 10);
  // }, []);

  const manageCommissionDataChange = (e: any, valueType: string) => {
    let tempobj = {
      ...manageCommissionData,
      [e.target.name]: e.target.value,
    };
    tempobj.agent_commission_value_type = valueType;
    setManageCommissionData(tempobj);
  };

  const handleEdit = (index: number) => {
    console.log(getAllAgent[index], "getAllAgent[index]getAllAgent[index]");
    setEditFormData({ ...getAllAgent[index], index });
    setisshowmore(true);
  };

  const handleDeleteAgent = async (index: number) => {
    let deleteuserid = getAllAgent[index].id;
    const responseJson = await AppService.deleteAgent(deleteuserid);

    if (responseJson.status == 204) {
      Alert("Agent Deleted Successfully");
      // let tempArr = getAllAgent;
      // const indexid = tempArr.indexOf(index);
      // if (indexid > -1) {
      //     tempArr.splice(indexid, 1);
      // }
      // setGetAllAgent([...tempArr]);
      // getAllLists(currentPage);
      getAgents();
    }
    console.log("all agents list===>", responseJson);
  };

  const handleKycApproveAgent = async (index: number, agent_status: string) => {
    if (agent_status == "KYCREJECTED") {
      setAddRejectModalOpen(true);
      setisshowmore(false);
      setAgentId(index);
    } else {
      let textmsg = "";
      if (agent_status == "KYCAPPROVED") {
        textmsg = "Do You want to approve agent KYC ?";
      }
      if (agent_status == "KYCREJECTEDWITHREASON") {
        textmsg = "Do You want to reject agent KYC ?";
      }
      if (window.confirm(textmsg)) {
        let agentData = getAllAgent[index];

        let body = {
          agent_status:
            agent_status === "KYCREJECTEDWITHREASON"
              ? "KYCREJECTED"
              : agent_status,
          reject_reason: rejectReason,
        };
        const responseJson = await AppService.kycApproveAgent(
          agentData.id,
          body
        );

        if (responseJson.status == 200) {
          setisshowmore(false);
          if (agent_status == "KYCAPPROVED") {
            Alert("Agent KYC APPROVED Successfully");
            setAddCommitionModalOpen(true);
          }
          if (agent_status == "KYCREJECTEDWITHREASON") {
            Alert("Agent KYC REJECTED Successfully");
          }
          // getAllLists(currentPage);
          getAgents();
          setAddRejectModalOpen(false);
        }
        console.log("all agents list===>", responseJson);
      }
    }

    setisshowmore(false);
  };
  const handleAgentStatus = async (e: any, index: number, item: any) => {
    let textmsg = "";
    if (e.target.checked) {
      textmsg = "Do you want to active Agent ?";
    } else {
      textmsg = "Do you want to Inactive Agent ?";
    }

    if (!window.confirm(textmsg)) {
      return;
    }

    let body = {
      agent_active: e.target.checked,
    };

    const responseJson = await AppService.kycApproveAgent(item.id, body);
    console.log(item, responseJson, "item");
    if (responseJson.status == 200) {
      if (e.target.checked) {
        Alert("Agent Inactive Successfully");
      } else {
        Alert("Agent Active Successfully");
      }

      // let tempArr = getAllAgent;
      // const indexid = tempArr.indexOf(index);
      // if (indexid > -1) {
      //     tempArr.splice(indexid, 1);
      // }
      // setGetAllAgent([...tempArr]);
    }
    // getAllLists(currentPage);
    getAgents();
    console.log("all agents list===>", responseJson);
  };

  const handleAddCommissionModal = async (index: number) => {
    setEditFormData({ ...getAllAgent[index], index });
    let obj = {
      agent_commission_value_type:
        getAllAgent[index].agent_commission_value_type,
      agent_commission_type: getAllAgent[index].agent_commission_type,
      agent_commission_value: getAllAgent[index].agent_commission_value,
    };
    setManageCommissionData(obj);
    setSelectedTab(getAllAgent[index].agent_commission_type);
    setAddCommitionModalOpen(true);
  };

  const handleAddCommissionSubmit = async () => {
    console.log(
      "LOGGER COMMISSION ====> ",
      manageCommissionData.agent_commission_value,
      isNaN(Number(manageCommissionData.agent_commission_value)),
      manageCommissionData
    );
    if (
      !manageCommissionData.agent_commission_value.trim() ||
      isNaN(Number(manageCommissionData.agent_commission_value.trim()))
    ) {
      alert("Please enter Valid Commmission");
      return;
    }

    // return;

    let data = manageCommissionData;
    // data.agent_commission_type = selectedTab;
    let agentData = getAllAgent[editFormData.index];
    // delete agentData.agent_commission_value;

    agentData = { ...agentData, ...data };
    console.log("agentData.agent_pancard_image", agentData.agent_pancard_image);

    let body = manageCommissionData;

    if (!agentData.agent_commission_value.includes("-")) {
      try {
        // agentData.agency = agentData?.agency?.id
        console.log(agentData, "agentDataagentData");
        const responseJson = await AppService.kycApproveAgent(
          agentData.id,
          body
        );
        if (responseJson.status == 200) {
          setAddCommitionModalOpen(false);
          Alert("Commission updated Successfully");
          // getAllLists(currentPage);
          getAgents();
        }
      } catch (error: any) {
        setAddCommitionModalOpen(false);
        console.log("error", error);
        let message = error.response.data.type + "\n";
        error.response.data.errors.map((row: any) => {
          message += row.attr + " : " + row.detail + "\n";
        });
        AlertError(message);
      }
    } else {
      AlertError("enter a positive value");
      setAddCommitionModalOpen(false);
    }
  };
  useEffect(() => {
    // getAllAgentTypes()
    getAllAgentTypeData();
    // getAllActiveInactive()
    getAllActiveInactiveData();
  }, []);
  useEffect(() => {
    // filterallAgent?.map((item: any) => {
    //     getAllDis({ ids: item?.district }, "")
    //     getAllState({ ids: item?.state }, "")
    //     getAllCity({ ids: item?.city }, "")
    // });
    getAllBazaar();
    // getAllStateData()
    // getAllDisData()
    // getAllCityData()
    getAllState();
    // getAllAgentTypes()
    getAllAgentTypeData();
    // getAllActiveInactive()
    // getAllActiveInactiveData()
    // getAllStatus()
    // getAllStatusData()
  }, []);

  useEffect(() => {
    getAllBazaarData();
  }, []);

  const getAllDis = async (param: any, type: any) => {
    const responseJson = await AppService.getTotalDistrict(param);
    setAllDis(responseJson.data);
  };
  const getAllState = async (param: any = {}, type: any = "") => {
    const responseJson = await AppService.getTotalCity(param);
    setAllState(responseJson.data);
  };
  const getAllCity = async (param: any, type: any) => {
    const responseJson = await AppService.getAllCity(param);
    setAllCity(responseJson.data.results);
  };
  const getAllBazaarData = async () => {
    const responseJson = await AppService.getAllBazaar();
    setAllBazaarData(responseJson.data.results);
  };
  const getAllBazaar = async () => {
    const responseJson = await AppService.getAllBazaars();
    let tempBazaar = await responseJson.data.map((row: any) => {
      return {
        label: row.bazaar_name,
        value: row.id,
      };
    });
    setBazaarList(tempBazaar);
  };

  const getAllStateData = async (params: any) => {
    setstateList([]);
    setdisList([]);
    setcityList([]);
    try {
      const responseJson = await AppService.getAllStateByBazaar(params);

      const tempState = responseJson.data.results
        .flatMap((row: any) =>
          row.bazaar_state.map((id: any) => {
            const stateItem = AllState.find((item: any) => item.id === id);
            if (stateItem) {
              return {
                label: stateItem.state,
                value: id,
              };
            }
            return null; // Handle cases where a matching state is not found
          })
        )
        .filter((item: any) => item !== null);

      console.log("logger123...", AllState, responseJson.data, tempState);
      setstateList(tempState);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  };

  const getAllDisData = async (params: any) => {
    setdisList([]);
    setcityList([]);
    const responseJson = await AppService.getAllDistricByState(params);
    const tempState = responseJson.data.results
      .flatMap((row: any) =>
        row.district.map((item: any) => {
          return {
            label: item.district,
            value: item.id,
          };
        })
      )
      .filter((item: any) => item !== null);

    console.log("logger123...", allDis, responseJson.data, tempState);
    setdisList(tempState);
  };

  const getAllCityData = async (params: any) => {
    const responseJson = await AppService.getAllCityByDis(params);
    const tempState = responseJson.data.results
      .flatMap((row: any) =>
        row.city.map((item: any) => {
          return {
            label: item.city,
            value: item.id,
          };
        })
      )
      .filter((item: any) => item !== null);
    setcityList(tempState);
  };

  // const getAllAgentTypes = async () => {
  //     const responseJson = await AppService.getAllAgents({ page: currentPage });
  //     const arr = responseJson?.data?.results?.map((item: any) => item.agent_type);
  //     var uniqueArray = Array.from(new Set(arr));
  //     setAllAgentType(uniqueArray);
  // }
  const getAllAgentTypeData = async () => {
    if (AllAgentType.length > 0) {
      let tempState = AllAgentType?.map((row: any) => {
        return {
          label: row,
          value: row,
        };
      });
      setagentTypeList(tempState);
    }
  };
  const getAllActiveInactive = async () => {
    const responseJson = await AppService.getAllAgents({ page: currentPage });
    const arr = responseJson.data.results?.map((item: any) => {
      if (item?.agent_active === true) {
        return true;
      } else {
        return false;
      }
    });
    var uniqueArray = Array.from(new Set(arr));
    setAllActiveInactive(uniqueArray);
  };
  const getAllActiveInactiveData = async () => {
    if (AllActiveInactive.length > 0) {
      let tempState = AllActiveInactive.map((row: any) => {
        return {
          label: row === true ? "Enable" : "Disable",
          value: row === true ? true : false,
        };
      });
      setactiveInactiveList(tempState);
    }
  };
  const getAllStatus = async () => {
    const responseJson = await AppService.getAllAgents({ page: currentPage });
    const arr = responseJson.data.results?.map(
      (item: any) => item?.agent_status
    );
    var uniqueArray = Array.from(new Set(arr));
    console.log("logger123...", uniqueArray, arr);
    getAllStatusData(uniqueArray);
  };
  const getAllStatusData = async (uniqueArray: any[]) => {
    if (uniqueArray?.length > 0) {
      let tempState = uniqueArray?.map((row: any) => {
        return {
          label: row,
          value: row,
        };
      });
      setstatusList(tempState);
    }
  };

  const handleChange = (selectboxName: string, id: any) => {
    setFilters(true);
    setCurrentPage(1);
    if (selectboxName === "State") {
      const updatedData = stateList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      const ids = updatedData
        .filter((item: any) => item.status === true)
        .map((item: any) => item.value)
        .join(",");
      console.log("logger123...", updatedData, id, AllState);
      getAllDisData({ ids });
      setstateList(updatedData);
    }
    if (selectboxName === "District") {
      const updatedData = disList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      const ids = updatedData
        .filter((item: any) => item.status === true)
        .map((item: any) => item.value)
        .join(",");
      getAllCityData({ ids });

      setdisList(updatedData);
    }
    if (selectboxName === "City") {
      setcityList((prev: any) => {
        return prev.map((item: any) =>
          item.value === id ? { ...item, status: !item.status } : item
        );
      });
    }
    if (selectboxName === "Wholeseller Type") {
      setWholesellerlist((prev: any) => {
        return prev.map((item: any) =>
          item.value === id ? { ...item, status: !item.status } : item
        );
      });
    }
    if (selectboxName === "Agent Type") {
      setagentTypeList((prev: any) => {
        return prev.map((item: any) =>
          item.value === id ? { ...item, status: !item.status } : item
        );
      });
    }
    if (selectboxName === "Status") {
      setstatusList((prev: any) => {
        return prev.map((item: any) =>
          item.value === id ? { ...item, status: !item.status } : item
        );
      });
    }
    if (selectboxName === "Bazaar") {
      const updatedData = bazaarList.map((item: any) =>
        item.value === id ? { ...item, status: !item.status } : item
      );
      const ids = updatedData
        .filter((item: any) => item.status === true)
        .map((item: any) => item.value)
        .join(",");
      getAllStateData({ ids });
      setBazaarList(updatedData);
    }
    if (selectboxName === "Active/Inactive") {
      setactiveInactiveList((prev: any) => {
        return prev.map((item: any) =>
          item.value === id ? { ...item, status: !item.status } : item
        );
      });
    }
  };

  // const filterdata = async (param: any) => {
  //     const data = await AppService.getAgentByfilter(param)
  //     if (data?.status === 200) {
  //         console.log('data',data?.data?.results)
  //         setGetAllAgent(data?.data?.results)
  //     }
  // }

  // useEffect(() => {
  //     if (filterData) {
  //         filterdata(filterData)
  //     }
  // }, [filterData])

  useEffect(() => {
    const parmsData: any = {};
    let temp1 = filterAllAgentM;
    // bazaar data
    if (filterAllAgentM?.length && AllBazaarData?.length) {
      let FBazaardata = AllBazaarData?.filter(
        (b: any) => b.status === true
      )?.map((item: any) => item?.id);
      if (FBazaardata?.length > 0) {
        for (const x of FBazaardata) {
          temp1 = temp1?.filter((fp: any) => fp?.agent_bazaar?.includes(x));
        }
      }
    }
    // city
    if (temp1?.length && AllCity?.length) {
      let FCity = AllCity?.filter((b: any) => b.status === true)?.map(
        (item: any) => item?.id
      );
      if (FCity?.length > 0) {
        // parmsData['city_id'] = Object.values(FCity)
        setFilterData((prev: any) => ({ ...prev, city_id: FCity[0] }));
        for (const x of FCity) {
          temp1 = temp1?.filter((fc: any) =>
            fc?.agent_assigned_city?.includes(x)
          );
        }
      }
    }
    // state
    if (temp1?.length && AllState?.length) {
      let FState = AllState?.filter((b: any) => b.status === true)?.map(
        (item: any) => item?.id
      );
      if (FState?.length > 0) {
        // parmsData['state_id'] = Object.values(FState)
        setFilterData((prev: any) => ({ ...prev, state_id: FState[0] }));
        for (const x of FState) {
          temp1 = temp1?.filter((fs: any) =>
            fs?.agent_assigned_state?.includes(x)
          );
        }
      }
    }
    // district
    if (temp1?.length && allDis?.length) {
      let FDistrict = allDis
        ?.filter((b: any) => b.status === true)
        ?.map((item: any) => item?.id);
      if (FDistrict?.length > 0) {
        // parmsData['district_id'] = Object.values(FDistrict)
        setFilterData((prev: any) => ({ ...prev, district_id: FDistrict[0] }));
        for (const x of FDistrict) {
          temp1 = temp1?.filter((fs: any) =>
            fs?.agent_assigned_district?.includes(x)
          );
        }
      }
    }
    // agent type
    if (temp1?.length && AllAgentType?.length) {
      const selectedAgentType = agentTypeList
        ?.filter((item: any) => item.status === true)
        .map((item: any) => item.value);
      if (selectedAgentType?.length > 0) {
        // parmsData['agent_type'] = Object.values(selectedAgentType)
        setFilterData((prev: any) => ({
          ...prev,
          agent_type: selectedAgentType,
        }));
        temp1 = temp1?.filter((item: any) =>
          selectedAgentType?.includes(item?.agent_type)
        );
      }
    }
    // agent status
    if (temp1?.length && statusList?.length) {
      const selectedStatus = statusList
        ?.filter((item: any) => item.status === true)
        .map((item: any) => item.value);
      if (selectedStatus?.length > 0) {
        // parmsData['agent_status'] = Object.values(selectedStatus)
        setFilterData((prev: any) => ({
          ...prev,
          agent_status: selectedStatus[0],
        }));
        temp1 = temp1?.filter((item: any) =>
          selectedStatus?.includes(item?.agent_status)
        );
      }
    }
    // active / inactive
    if (temp1?.length && AllActiveInactive?.length) {
      const selectedActiveInactive = activeInactiveList
        ?.filter((item: any) => item.status === true)
        .map((item: any) => item.value);
      if (selectedActiveInactive?.length > 0) {
        // parmsData['agent_active'] = Object.values(selectedActiveInactive)
        setFilterData((prev: any) => ({
          ...prev,
          agent_active: selectedActiveInactive[0],
        }));
        temp1 = temp1?.filter((item: any) =>
          selectedActiveInactive?.includes(item?.agent_active)
        );
      }
    }
  }, [
    filterAllAgentM,
    AllBazaarData,
    AllCity,
    AllState,
    allDis,
    activeInactiveList,
    statusList,
    agentTypeList,
  ]);

  return (
    <>
      <DashboardLayout>
        {loading ? (
          <Loading />
        ) : (
          <div className={classes.root}>
            <div>
              <div
                className="flex align-middle justify-between items-center agent-wrapper"
                style={{ flexWrap: "wrap" }}
              >
                <div
                  className="flex gap-[30px] items-center"
                  style={{ flexWrap: "wrap" }}
                >
                  <p className="commonTitle headTitle">Agents</p>
                  {/* <div className="commonTitle flex gap-2 cursor-pointer" style={{ alignItems: "center" }}>
                                            <BsFileEarmarkPlus className="w-10px text-[#FF6652]" style={{ color: "#333" }} />
                                            <p
                                                className="paymentTitle"
                                                onClick={() => navigate("/paymentrequest")}
                                            >
                                                {totalCount || 0} Payment Requests
                                            </p>
                                        </div> */}
                </div>

                <div className="flex gap-2 search-div">
                  <div
                    className="relative"
                    style={{ width: "576px", marginRight: "10px" }}
                  >
                    <input
                      value={searchK}
                      type="text"
                      onChange={(e) => setSearchK(e.target.value)}
                      placeholder="Search by City , Name, Mobile Number, Type etc"
                      className="w-full py-[11px] pl-12 pr-4 text-gray-500 border rounded-md outline-none bg-gray-50 focus:bg-white"
                      style={{
                        fontSize: "14px",
                        fontWeight: 500,
                        fontFamily: "Manrope",
                        color: "#84818A",
                        paddingLeft: "16px",
                      }}
                      // onKeyDown={(e:any)=>e.key === 'Enter' && }
                    />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute top-0 bottom-0 w-6 h-6 my-auto text-gray-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      style={{
                        right: "16px",
                        color: "#504F54",
                        width: "17.49px",
                        height: "17.49px",
                      }}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                      />
                    </svg>
                  </div>
                  <Tooltip
                    title={
                      !permissions.includes("agent.create")
                        ? "Not Authorized"
                        : ""
                    }
                    placement="bottom"
                  >
                    <span>
                      <AddButton
                        label="Create New Agent"
                        onClick={() => {
                          if (!permissions.includes("agent.create")) {
                            return;
                          }
                          navigate("/addagent");
                        }}
                      />
                    </span>
                  </Tooltip>
                </div>
              </div>
            </div>
            <div className="flex gap-5 pt-[30px] " style={{ flexWrap: "wrap" }}>
              <CommonSelectElectronicBazaar
                label={"Bazaar"}
                hint={"Select Bazaar"}
                options={bazaarList}
                handleSelect={handleChange}
                selectedIds={bazaarList
                  .filter((item: any) => item?.status)
                  .map((elm: any) => elm.value)}
                // selectedIds={AllBazaarData}
              />
              <CommonSelectElectronicBazaar
                label={"State"}
                hint={"Select State"}
                options={stateList}
                handleSelect={handleChange}
                selectedIds={stateList
                  .filter((item: any) => item?.status)
                  .map((elm: any) => elm.value)}
              />
              <CommonSelectElectronicBazaar
                label={"District"}
                hint={"Select District"}
                options={disList}
                handleSelect={handleChange}
                selectedIds={disList
                  .filter((item: any) => item?.status)
                  .map((elm: any) => elm.value)}
              />
              <CommonSelectElectronicBazaar
                label={"City"}
                hint={"Select City"}
                options={cityList}
                handleSelect={handleChange}
                selectedIds={cityList
                  .filter((item: any) => item?.status)
                  .map((elm: any) => elm.value)}
              />
              {/* <CommonSelectElectronicBazaar
                                    label={"Agent Type"}
                                    hint={"Select Agent Type"}
                                    options={agentTypeList}
                                    handleSelect={handleChange}
                                    selectedIds={agentTypeList?.filter((item: any) => item?.status).map((elm: any) => elm?.value)}
                                /> */}
              <CommonSelectElectronicBazaar
                label={"Status"}
                hint={"Select Status"}
                options={statusList}
                handleSelect={handleChange}
                selectedIds={statusList
                  ?.filter((item: any) => item?.status)
                  .map((elm: any) => elm?.value)}
              />
              <CommonSelectElectronicBazaar
                label={"Active/Inactive"}
                hint={"Select Active/Inactive"}
                options={activeInactiveList}
                handleSelect={handleChange}
                selectedIds={activeInactiveList
                  ?.filter((item: any) => item?.status)
                  .map((elm: any) => elm?.value)}
              />
            </div>

            <div>
              <div
                className="relative pt-[40px] pb-[125px]"
                style={{ overflowX: "auto" }}
              >
                <table className="w-full text-left">
                  <thead className="">
                    <tr
                      className="color-[#2E2C34;]"
                      style={{ borderBottom: "1px solid #EBEAED" }}
                    >
                      <th
                        scope="col"
                        className="tableTitle py-3 px-6"
                        style={{ paddingLeft: 0 }}
                      >
                        SR No.
                      </th>
                      <th
                        scope="col"
                        className="tableTitle py-3 px-4"
                        style={{ paddingLeft: 0 }}
                      >
                        Name
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4">
                        Mobile Number
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4">
                        City
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4">
                        Bazaar
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4">
                        Type
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4">
                        Commision
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4">
                        Status
                      </th>
                      <th
                        scope="col"
                        className="tableTitle py-3 px-4 text-center"
                      >
                        Enable/Disable
                      </th>
                      <th scope="col" className="tableTitle py-3 px-4"></th>
                    </tr>
                  </thead>
                  <tbody>
                    {getAllAgent?.length ? (
                      getAllAgent?.map((item: any, index: any) => (
                        <tr className="border-b" key={index}>
                          <td className="py-4 px-6 tableData">
                            {(currentPage - 1) * 10 + index + 1}
                          </td>

                          <td
                            scope="row"
                            className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                            style={{ paddingLeft: 0 }}
                          >
                            {item.agent_status == "KYCAPPROVED" ? (
                              <div
                                className="flex items-center gap-2 text-[#4E2FA9] cursor-pointer"
                                onClick={() =>
                                  navigate(`/agentdetails/${item.id}`)
                                }
                              >
                                <img
                                  className="brandLogo"
                                  src={contact}
                                  alt={"Logo"}
                                />
                                <span style={{ textDecoration: "underline" }}>
                                  {item.agent_name}
                                </span>
                              </div>
                            ) : (
                              <div className="flex gap-[20px] items-center ">
                                <p
                                  className="mb-3 tableContentTitle"
                                  style={{ marginBottom: 0 }}
                                >
                                  <div className="flex gap-5 items-center">
                                    <p
                                      className="tableData"
                                      style={{ marginBottom: 0 }}
                                    >
                                      {item.agent_name}
                                    </p>
                                  </div>
                                </p>
                              </div>
                            )}
                          </td>
                          <td className="py-4 px-6 tableData">
                            <div className="flex gap-2">
                              <img src={icPhoneHandle} alt="dots" />
                              {item.agent_number}
                            </div>
                          </td>
                          <td className="py-4 px-6 tableData">
                            {item.agent_city_name || "NA"}
                          </td>
                          <td className="py-4 px-6 tableData">
                            <span className="font-[500] text-[#2E2C34] text-[14px] font-[Manrope]">
                              {item.agent_bazaar_data
                                .map(
                                  (item: any, index: any) => item.bazaar_name
                                )
                                ?.join(", ")}
                            </span>
                          </td>
                          <td
                            className="py-4 px-6 tableData"
                            style={{ textTransform: "capitalize" }}
                          >
                            {item.agent_type}
                          </td>

                          <td
                            className="py-4 px-6 tableData text-center"
                            style={{ textTransform: "capitalize" }}
                          >
                            {" "}
                            {item.agent_commission_type || "--"} <br />{" "}
                            {item.agent_commission_value || "0"}{" "}
                            {item.agent_commission_value_type === "AMOUNT"
                              ? "₹"
                              : item.agent_commission_value_type ===
                                "PERCENTAGE"
                              ? "%"
                              : "--"}
                          </td>

                          <td className="py-4 px-6 tableData">
                            {item.agent_status == "CREATED" && (
                              <div className="bg-[#e5f5ff] flex justify-center rounded-md p-[10px] w-[150px]">
                                <p
                                  className="text-[#28a1ff]"
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {item.agent_status}
                                </p>
                              </div>
                            )}
                            {item.agent_status == "PENDING" && (
                              <div
                                className="bg-[#FFF6ED] cursor-pointer flex justify-center rounded-md p-[10px] w-[150px]"
                                onClick={() => handleEdit(index)}
                              >
                                <p
                                  className="text-[#FFA043]"
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {item.agent_status}
                                </p>
                              </div>
                            )}
                            {item.agent_status == "KYCAPPROVED" && (
                              <div className="bg-[#e6fcf7] flex justify-center rounded-md p-[10px] w-[150px]">
                                <p
                                  className="text-[#00e0c0]"
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {item.agent_status}
                                </p>
                              </div>
                            )}
                            {item.agent_status == "KYCREJECTED" && (
                              <div className="bg-[#ffeae5] flex justify-center rounded-md p-[10px] w-[150px]">
                                <p
                                  className="text-[#ff0000]"
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {item.agent_status}
                                </p>
                              </div>
                            )}
                            {item.agent_status == "APPROVED" && (
                              <div className="bg-[#FFF6ED] flex justify-center rounded-md p-[10px] w-[150px]">
                                <p
                                  className="text-[#FFA043]"
                                  style={{
                                    fontSize: "12px",
                                    lineHeight: "18px",
                                  }}
                                >
                                  {item.agent_status}
                                </p>
                              </div>
                            )}
                          </td>
                          <td className="py-4 px-6 tableData cursor-pointer">
                            <div className="text-center">
                              <Switch
                                checked={item.agent_active}
                                onChange={(e: any) =>
                                  handleAgentStatus(e, index, item)
                                }
                                name="gilad"
                              />
                            </div>
                          </td>
                          <td
                            className="py-4 px-6 tableData cursor-pointer"
                            style={{
                              justifyContent: "end",
                              display: "flex",
                              paddingRight: 0,
                            }}
                          >
                            {item.agent_status == "CREATED" && (
                              <GridOptionButton
                                icon={"vertical-options"}
                                menus={[
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img src={complete} alt="dots" />
                                        </span>
                                        <p
                                          onClick={() =>
                                            navigate(`/agentkyc/${item.id}`)
                                          }
                                        >
                                          Complete KYC
                                        </p>
                                      </>
                                    ),
                                  },
                                  {
                                    label: (
                                      <Tooltip
                                        title={
                                          !permissions.includes("agent.update")
                                            ? "Not Authorized"
                                            : ""
                                        }
                                        placement="bottom"
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            className="icon"
                                            style={{ marginRight: "15px" }}
                                          >
                                            <img src={fill} alt="fill" />{" "}
                                          </span>
                                          <p
                                            onClick={() => {
                                              if (
                                                !permissions.includes(
                                                  "agent.update"
                                                )
                                              ) {
                                                return;
                                              }
                                              navigate(`/addagent/${item.id}`);
                                            }}
                                          >
                                            Edit Agent
                                          </p>
                                        </span>
                                      </Tooltip>
                                    ),
                                  },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={deleteagent} alt="deleteagent" />
                                  //             </span>
                                  //             Delete Agent
                                  //         </>
                                  //     ),
                                  //     onClick() {
                                  //         handleDeleteAgent(index)
                                  //     },
                                  // },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={calendar} alt="calendar" />
                                  //             </span>
                                  //             <p
                                  //                 onClick={() =>
                                  //                     handleAddCommissionModal(index)
                                  //                 }
                                  //             >
                                  //                 Manage Commission
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                ]}
                              />
                            )}
                            {item.agent_status == "PENDING" && (
                              <GridOptionButton
                                icon={"vertical-options"}
                                menus={[
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img src={complete} alt="dots" />
                                        </span>
                                        <p onClick={() => handleEdit(index)}>
                                          View Kyc
                                        </p>
                                      </>
                                    ),
                                  },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={fill} alt="fill" />{" "}
                                  //             </span>
                                  //             <p onClick={() => handleEdit(index)}>
                                  //                 Edit Agent
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={deleteagent} alt="deleteagent" />
                                  //             </span>
                                  //             Delete Agent
                                  //         </>
                                  //     ),
                                  //     onClick() {
                                  //         handleDeleteAgent(index)
                                  //     },
                                  // },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={calendar} alt="calendar" />
                                  //             </span>
                                  //             <p
                                  //                 onClick={() =>
                                  //                     handleAddCommissionModal(index)
                                  //                 }
                                  //             >
                                  //                 Manage Commission
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                ]}
                              />
                            )}
                            {item.agent_status == "KYCAPPROVED" && (
                              <GridOptionButton
                                icon={"vertical-options"}
                                menus={[
                                  {
                                    label: (
                                      <>
                                        <Tooltip
                                          title={
                                            !permissions.includes(
                                              "agent.update"
                                            )
                                              ? "Not Authorized"
                                              : ""
                                          }
                                          placement="bottom"
                                        >
                                          <span
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                            }}
                                          >
                                            <span
                                              className="icon"
                                              style={{ marginRight: "15px" }}
                                            >
                                              <img src={complete} alt="dots" />
                                            </span>
                                            <p
                                              onClick={() => {
                                                if (
                                                  !permissions.includes(
                                                    "agent.update"
                                                  )
                                                ) {
                                                  return;
                                                }
                                                navigate(
                                                  "/updatebranch/" + item.id
                                                );
                                              }}
                                            >
                                              Update Bazaar
                                            </p>
                                          </span>
                                        </Tooltip>
                                      </>
                                    ),
                                  },
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img src={complete} alt="dots" />
                                        </span>
                                        <p onClick={() => handleEdit(index)}>
                                          View Kyc
                                        </p>
                                      </>
                                    ),
                                  },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={fill} alt="fill" />{" "}
                                  //             </span>
                                  //             <p onClick={() => handleEdit(index)}>
                                  //                 Edit Agent
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                  {
                                    label: (
                                      <Tooltip
                                        title={
                                          !permissions.includes("agent.delete")
                                            ? "Not Authorized"
                                            : ""
                                        }
                                        placement="bottom"
                                      >
                                        <span
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <span
                                            className="icon"
                                            style={{ marginRight: "15px" }}
                                          >
                                            <img
                                              src={deleteagent}
                                              alt="deleteagent"
                                            />
                                          </span>
                                          <p>Delete Agent</p>
                                        </span>
                                      </Tooltip>
                                    ),
                                    onClick() {
                                      if (
                                        !permissions.includes("agent.delete")
                                      ) {
                                        return;
                                      }

                                      handleDeleteAgent(index);
                                    },
                                  },
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img src={calendar} alt="calendar" />
                                        </span>
                                        <p
                                          onClick={() =>
                                            handleAddCommissionModal(index)
                                          }
                                        >
                                          Manage Commission
                                        </p>
                                      </>
                                    ),
                                  },
                                ]}
                              />
                            )}
                            {item.agent_status == "KYCREJECTED" && (
                              <GridOptionButton
                                icon={"vertical-options"}
                                menus={[
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img src={complete} alt="dots" />
                                        </span>
                                        <p
                                          onClick={() =>
                                            navigate(`/agentkyc/${item.id}`)
                                          }
                                        >
                                          Complete KYC
                                        </p>
                                      </>
                                    ),
                                  },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={fill} alt="fill" />{" "}
                                  //             </span>
                                  //             <p onClick={() => handleEdit(index)}>
                                  //                 Edit Agent
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={deleteagent} alt="deleteagent" />
                                  //             </span>
                                  //             Delete Agent
                                  //         </>
                                  //     ),
                                  //     onClick() {
                                  //         handleDeleteAgent(index)
                                  //     },
                                  // },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={calendar} alt="calendar" />
                                  //             </span>
                                  //             <p
                                  //                 onClick={() =>
                                  //                     handleAddCommissionModal(index)
                                  //                 }
                                  //             >
                                  //                 Manage Commission
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                ]}
                              />
                            )}
                            {item.agent_status == "APPROVED" && (
                              <GridOptionButton
                                icon={"vertical-options"}
                                menus={[
                                  // {
                                  //     label: (
                                  //         <>
                                  //                 <span className="icon">
                                  //                     <img src={complete} alt="dots" />
                                  //                 </span>
                                  //                 <p onClick={() => navigate(`/agentkyc/${item.id}`)}>
                                  //                     Complete KYC
                                  //                 </p>

                                  //             </>
                                  //         ),
                                  // },
                                  // {
                                  //     label: (
                                  //         <>
                                  //             <span className="icon">
                                  //                 <img src={fill} alt="fill" />{" "}
                                  //             </span>
                                  //             <p onClick={() => handleEdit(index)}>
                                  //                 Edit Agent
                                  //             </p>
                                  //         </>
                                  //     ),
                                  // },
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img
                                            src={deleteagent}
                                            alt="deleteagent"
                                          />
                                        </span>
                                        Delete Agent
                                      </>
                                    ),
                                    onClick() {
                                      handleDeleteAgent(index);
                                    },
                                  },
                                  {
                                    label: (
                                      <>
                                        <span className="icon">
                                          <img src={calendar} alt="calendar" />
                                        </span>
                                        <p
                                          onClick={() =>
                                            handleAddCommissionModal(index)
                                          }
                                        >
                                          Manage Commission
                                        </p>
                                      </>
                                    ),
                                  },
                                ]}
                              />
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <div className="w-full py-5 flex justify-center items-center">
                        <p>No result found!</p>
                      </div>
                    )}
                  </tbody>
                </table>
                <div
                  className="flex items-center justify-between"
                  style={{ display: "flex", marginLeft: 0 }}
                >
                  <Pagination
                    count={Math.ceil(totalCount / 10)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </div>
            </div>
            {isshowmore && (
              <div ref={viewproductRef}>
                <div
                  className={
                    isshowmore ? "viewagent-modal active" : "viewagent-modal"
                  }
                >
                  <div className={classes.nativeDialog}>
                    <div
                      className="modalHead"
                      style={{ padding: "30px 30px 15px" }}
                    >
                      <p className="modalTitle">{editFormData?.agent_status}</p>
                      <div onClick={() => setisshowmore(false)}>
                        <svg
                          width="36"
                          height="36"
                          viewBox="0 0 36 36"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="18" cy="18" r="18" fill="#fff" />
                          <line
                            x1="24.7305"
                            y1="12.423"
                            x2="12.4268"
                            y2="24.7266"
                            stroke="#84818A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                          <line
                            x1="24.3104"
                            y1="24.7266"
                            x2="12.0068"
                            y2="12.4229"
                            stroke="#84818A"
                            strokeWidth="1.5"
                            strokeLinecap="round"
                          />
                        </svg>
                      </div>
                    </div>

                    {editFormData.agent_type === "AGENCY" ? (
                      <>
                        <div
                          className="headTitle"
                          style={{ padding: "0 30px" }}
                        >
                          <div>
                            <p className="martTitle">
                              {editFormData?.agent_agency_name}
                            </p>
                            <p className="martDescription">Agency</p>
                          </div>

                          <div>
                            <span
                              style={{
                                fontSize: "16px",
                                fontWeight: 600,
                                marginRight: "10px",
                              }}
                            >
                              {editFormData?.agent_active === true
                                ? "Active"
                                : "Inactive"}
                            </span>
                            <Switch
                              checked={editFormData?.agent_active}
                              onChange={(e: any) =>
                                handleAgentStatus(e, 0, editFormData)
                              }
                              name="gilad"
                            />
                          </div>
                        </div>

                        <div
                          className="datContainer"
                          style={{ padding: "22px 30px" }}
                        >
                          <p className="dataTitle">Agency Name</p>
                          <div>
                            <p className="metaData">
                              {editFormData?.agency?.firm_name}
                            </p>
                          </div>
                        </div>
                        <div
                          className="datContainer"
                          style={{ padding: "22px 30px" }}
                        >
                          <p className="dataTitle"> Address</p>
                          <div>
                            <p className="metaData">
                              {" "}
                              {editFormData?.agency?.address},
                              {editFormData?.agency?.city_name},{" "}
                              {editFormData?.agency?.state_name}
                            </p>
                            <p className="dataDescription flex gap-4 justify-end items-center py-[5px]">
                              <FaMapMarkerAlt />
                              Show Directions
                            </p>
                          </div>
                        </div>
                        <div
                          className="datContainer"
                          style={{ padding: "22px 30px" }}
                        >
                          <p className="dataTitle mr-3">Bazaar</p>
                          <div>
                            <div className="flex gap-2 flex-wrap justify-end">
                              {editFormData.agent_bazaar_data.map(
                                (item: any) => (
                                  <div className="group relative">
                                    <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm whitespace-nowrap">
                                      {item.bazaar_name}
                                    </button>
                                    {/* <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">
                                                                                    {item.bazaar_name}
                                                                                </span> */}
                                  </div>
                                )
                              )}
                            </div>
                          </div>
                        </div>
                        <div
                          className="datContainer"
                          style={{
                            padding: "22px 30px",
                            marginBottom: "40px",
                          }}
                        >
                          <div>
                            <p
                              className="dataTitle"
                              style={{ paddingBottom: "15px" }}
                            >
                              GST
                            </p>
                            <p className="dataTitle">PAN</p>
                          </div>
                          <div>
                            <p
                              className="metaData"
                              style={{ paddingBottom: "15px" }}
                            >
                              {editFormData?.agency?.gst_number}
                            </p>
                            <p className="metaData">
                              {editFormData?.agency?.pan_number}
                            </p>
                          </div>
                        </div>
                      </>
                    ) : null}

                    <div className="headTitle" style={{ padding: "0 30px" }}>
                      <div>
                        <p className="martTitle">{editFormData?.agent_name}</p>
                        <p className="martDescription">Agent</p>
                      </div>

                      {editFormData.agent_type !== "AGENCY" ? (
                        <div>
                          <span
                            style={{
                              fontSize: "16px",
                              fontWeight: 600,
                              marginRight: "10px",
                            }}
                          >
                            {editFormData?.agent_active === true
                              ? "Active"
                              : "Inactive"}
                          </span>
                          <Switch
                            checked={editFormData?.agent_active}
                            onChange={(e: any) =>
                              handleAgentStatus(e, 0, editFormData)
                            }
                            name="gilad"
                          />
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>

                    <div
                      className="datContainer "
                      style={{ padding: "22px 30px" }}
                    >
                      <div className="w-full flex justify-between items-center">
                        <p
                          className="dataTitle"
                          style={{ paddingBottom: "15px" }}
                        >
                          Agent Image
                        </p>
                        <img
                          src={editFormData?.agent_image}
                          className=" w-20 h-20 rounded-full"
                        />
                      </div>
                      {/* <div>
                                                    
                                                </div> */}
                    </div>

                    <div
                      className="datContainer"
                      style={{ padding: "22px 30px" }}
                    >
                      <p className="dataTitle">Contact Detail</p>
                      <div>
                        <p className="metaData">{editFormData?.agent_name}</p>
                        <p className="metaData flex gap-4 justify-end items-center py-[5px]">
                          <HiPhone />
                          {editFormData?.agent_number}
                        </p>
                        <p className="metaData"> {editFormData?.agent_email}</p>
                      </div>
                    </div>
                    <div
                      className="datContainer"
                      style={{ padding: "22px 30px" }}
                    >
                      <p className="dataTitle"> Address</p>
                      <div>
                        <p className="metaData">
                          {" "}
                          {editFormData?.agent_landmark},
                          {editFormData?.agent_city_name},{" "}
                          {editFormData?.agent_state_name}
                        </p>
                        <p className="dataDescription flex gap-4 justify-end items-center py-[5px]">
                          <FaMapMarkerAlt />
                          Show Directions
                        </p>
                      </div>
                    </div>

                    <div
                      className="datContainer"
                      style={{ padding: "22px 30px" }}
                    >
                      <p className="dataTitle mr-3">Bazaar</p>
                      <div>
                        <div className="flex gap-2 flex-wrap justify-end">
                          {editFormData.agent_bazaar_data.map((item: any) => (
                            <div className="group relative">
                              <button className="rounded bg-[#E6F6FF] px-4 py-2 text-sm text-[#00A5FF] shadow-sm whitespace-nowrap">
                                {item.bazaar_name}
                              </button>
                              {/* <span className="absolute top-10 scale-0 rounded bg-gray-800 p-2 text-xs text-white group-hover:scale-100 whitespace-nowrap">
                                                                        {item.bazaar_name}
                                                                    </span> */}
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <div
                      className="datContainer flex-col"
                      style={{ padding: "22px 30px" }}
                    >
                      <p className="dataTitle mr-3">Assigned Bazaars</p>
                      <div className="mt-3">
                        {editFormData.bazaar_assgined_data.map(
                          (item: any, index: number) => (
                            <Accordion>
                              <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                                <div className="flex items-center gap-3">
                                  <p className="mb-0">
                                    {
                                      editFormData.agent_bazaar_data.find(
                                        (ele: any, index: number) =>
                                          ele.id === item.bazaar_id
                                      ).bazaar_name
                                    }
                                  </p>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Accordion>
                                  <AccordionSummary
                                    expandIcon={<ExpandMore />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <div className="flex items-center gap-3">
                                      <p className="mb-0">Assigned States</p>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {item.state.map(
                                      (child: any, index1: number) => (
                                        <Accordion>
                                          <AccordionSummary
                                            expandIcon={<ExpandMore />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                          >
                                            <div className="flex items-center gap-3">
                                              <p className="mb-0">
                                                {item.state_names[index1]}
                                              </p>
                                            </div>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            <Accordion>
                                              <AccordionSummary
                                                expandIcon={<ExpandMore />}
                                                aria-controls="panel1a-content"
                                                id="panel1a-header"
                                              >
                                                <div className="flex items-center gap-3">
                                                  <p className="mb-0">
                                                    Assigned Districts
                                                  </p>
                                                </div>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {item.allDistricts
                                                  ?.find(
                                                    (state: any) =>
                                                      state.state === child
                                                  )
                                                  ?.district?.filter(
                                                    (dis: any) =>
                                                      item.district.includes(
                                                        dis.id
                                                      )
                                                  )
                                                  .map(
                                                    (
                                                      ele: any,
                                                      index: number
                                                    ) => {
                                                      return (
                                                        <Accordion>
                                                          <AccordionSummary
                                                            expandIcon={
                                                              <ExpandMore />
                                                            }
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                          >
                                                            <div className="flex items-center gap-3">
                                                              <p className="mb-0">
                                                                {ele.district}
                                                              </p>
                                                            </div>
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <Accordion>
                                                              <AccordionSummary
                                                                expandIcon={
                                                                  <ExpandMore />
                                                                }
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                              >
                                                                <div className="flex items-center gap-3">
                                                                  <p className="mb-0">
                                                                    Assigned
                                                                    Cities
                                                                  </p>
                                                                </div>
                                                              </AccordionSummary>
                                                              <AccordionDetails>
                                                                {item.allCities
                                                                  ?.find(
                                                                    (
                                                                      dis: any
                                                                    ) =>
                                                                      dis.district ===
                                                                      ele.id
                                                                  )
                                                                  ?.city?.filter(
                                                                    (
                                                                      city: any
                                                                    ) =>
                                                                      item.city.includes(
                                                                        city.id
                                                                      )
                                                                  )
                                                                  .map(
                                                                    (
                                                                      city: any,
                                                                      index: number
                                                                    ) => (
                                                                      <p>
                                                                        {
                                                                          city.city
                                                                        }
                                                                      </p>
                                                                    )
                                                                  )}
                                                              </AccordionDetails>
                                                            </Accordion>
                                                          </AccordionDetails>
                                                        </Accordion>
                                                      );
                                                    }
                                                  )}
                                              </AccordionDetails>
                                            </Accordion>
                                          </AccordionDetails>
                                        </Accordion>
                                      )
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              </AccordionDetails>
                            </Accordion>
                          )
                        )}
                      </div>
                    </div>

                    <div
                      className="datContainer"
                      style={{ padding: "22px 30px" }}
                    >
                      <div>
                        <p
                          className="dataTitle"
                          style={{ paddingBottom: "15px" }}
                        >
                          Aadhar
                        </p>
                        <p className="dataTitle">PAN</p>
                      </div>
                      <div>
                        <p
                          className="metaData"
                          style={{ paddingBottom: "15px" }}
                        >
                          {editFormData?.agent_adharcard_no}
                        </p>
                        <p className="metaData">
                          {editFormData?.agent_pancard_no}
                        </p>
                      </div>
                    </div>

                    <div
                      className="datContainer"
                      style={{ padding: "22px 30px" }}
                    >
                      {editFormData?.agent_type === "AGENCY" ? (
                        <div>
                          <p
                            className="dataTitle"
                            style={{ paddingBottom: "15px" }}
                          >
                            Agency GST Image
                          </p>
                          <img
                            src={
                              "https://api.mwbbazaar.com/media" +
                              editFormData?.agency?.gst_image?.split("media")[1]
                            }
                            className=" max-w-full "
                          />
                          <p
                            className="dataTitle"
                            style={{
                              paddingBottom: "15px",
                              paddingTop: "25px",
                            }}
                          >
                            Agency PAN Image
                          </p>
                          <img
                            src={
                              "https://api.mwbbazaar.com/media" +
                              editFormData?.agency?.pancard_image?.split(
                                "media"
                              )[1]
                            }
                            className=" max-w-full "
                          />
                        </div>
                      ) : null}
                    </div>

                    <div
                      className="datContainer"
                      style={{ padding: "22px 30px" }}
                    >
                      <div>
                        <p
                          className="dataTitle"
                          style={{ paddingBottom: "15px" }}
                        >
                          Adhaar Front Image
                        </p>
                        <img
                          src={editFormData?.agent_adhar_front_image}
                          className=" max-w-full "
                        />
                        <p
                          className="dataTitle"
                          style={{ paddingBottom: "15px", paddingTop: "25px" }}
                        >
                          Adhaar Back Image
                        </p>
                        <img
                          src={editFormData?.agent_adhar_back_image}
                          className=" max-w-full "
                        />

                        <p
                          className="dataTitle"
                          style={{ paddingBottom: "15px", paddingTop: "25px" }}
                        >
                          Pan Image
                        </p>
                        <img
                          src={editFormData?.agent_pancard_image}
                          className=" max-w-full "
                        />
                      </div>
                      {/* <div>
                                                    
                                                </div> */}
                    </div>

                    <div
                      className={"action-bar"}
                      style={{
                        padding: "22px 30px",
                        flexWrap: "wrap",
                        marginTop: 0,
                      }}
                    >
                      <ActionButton
                        variant={"default"}
                        title={"Cancel"}
                        onClick={() => setisshowmore(false)}
                      />

                      {editFormData?.agent_status == "PENDING" && (
                        <Tooltip
                          title={
                            !permissions.includes("agent.approve")
                              ? "Not Authorized"
                              : ""
                          }
                          placement="top"
                        >
                          <span>
                            <ActionButton
                              variant={"primary"}
                              title={"Approve"}
                              disabled={!permissions.includes("agent.approve")}
                              onClick={() => {
                                if (!permissions.includes("agent.approve")) {
                                  return;
                                }

                                handleKycApproveAgent(
                                  editFormData.index,
                                  "KYCAPPROVED"
                                );
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}

                      {editFormData?.agent_status == "PENDING" && (
                        <Tooltip
                          title={
                            !permissions.includes("agent.reject")
                              ? "Not Authorized"
                              : "something"
                          }
                        >
                          <span>
                            <ActionButton
                              disabled={!permissions.includes("agent.reject")}
                              variant={"primary"}
                              title={"Reject"}
                              onClick={() => {
                                if (!permissions.includes("agent.reject")) {
                                  return;
                                }
                                handleKycApproveAgent(
                                  editFormData.index,
                                  "KYCREJECTED"
                                );
                              }}
                            />
                          </span>
                        </Tooltip>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            <Dialog
              open={addRejectModalOpen}
              maxWidth={"lg"}
              sx={{
                ".MuiPaper-root": {
                  borderRadius: "6px",
                },
              }}
              onClose={() => setisshowmore(false)}
            >
              <div className={classes.addDialog}>
                <div className="flex gap-5 justify-between">
                  <p className="rejectModalTitle">Reject KYC</p>
                  <div
                    onClick={() => setAddRejectModalOpen(false)}
                    className="cursor-pointer"
                  >
                    <svg
                      width="36"
                      height="36"
                      viewBox="0 0 36 36"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="18" cy="18" r="18" fill="#E1E1E1" />
                      <line
                        x1="24.7305"
                        y1="12.423"
                        x2="12.4268"
                        y2="24.7266"
                        stroke="#84818A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                      <line
                        x1="24.3104"
                        y1="24.7266"
                        x2="12.0068"
                        y2="12.4229"
                        stroke="#84818A"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                </div>
                <div className="pt-[20px]">
                  <p className="fieldText">Reason</p>
                  <TextField
                    sx={{
                      "& *": {
                        fontFamily: "Manrope !important",
                        fontSize: "14px",
                      },
                    }}
                    variant="standard"
                    fullWidth={true}
                    // name="agency_name"
                    value={rejectReason}
                    onChange={(e: any) => setRejectReason(e.target.value)}
                  />
                </div>
                <div className="flex gap-4 justify-center items-center pt-[30px] pb-[10px]">
                  <ActionButton
                    variant={"default"}
                    title={"Cancel"}
                    onClick={() => setAddRejectModalOpen(false)}
                  />

                  <ActionButton
                    variant={"primary"}
                    title={"Reject KYC"}
                    onClick={() =>
                      handleKycApproveAgent(agentId, "KYCREJECTEDWITHREASON")
                    }
                  />
                </div>
              </div>
            </Dialog>

            <Dialog
              open={addCommisionModalOpen}
              maxWidth={"lg"}
              sx={{
                ".MuiPaper-root": {
                  borderRadius: "20px",
                },
              }}
              onClose={() => setisshowmore(false)}
            >
              <div className={classes.addDialog}>
                <div>
                  <p className="comissionTitle pb-[30px]">
                    {editFormData?.agent_status == "PENDING"
                      ? "Set Commission"
                      : "Manage Commission"}
                  </p>
                  <div>
                    <div className="radio-actionButton flex gap-5">
                      <div className="radio-button">
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="controlled-radio-buttons"
                            value={selectedTab}
                            onChange={() => {
                              setManageCommissionData({
                                ...manageCommissionData,
                                agent_commission_value_type: "AMOUNT",
                                agent_commission_type: "PERCUSTOMER",
                              });
                              // setSelectedTab("PERCUSTOMER")
                            }}
                          >
                            <FormControlLabel
                              control={<Radio />}
                              checked={
                                manageCommissionData?.agent_commission_type ===
                                "PERCUSTOMER"
                              }
                              label={
                                <div className="flex gap-4 items-center">
                                  Per Customer
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                      <div className="radio-button">
                        <FormControl>
                          <RadioGroup
                            aria-labelledby="radio-buttons"
                            name="controlled-radio-buttons"
                            value={selectedTab}
                            onChange={() => {
                              setManageCommissionData({
                                ...manageCommissionData,
                                agent_commission_value_type: "PERCENTAGE",
                                agent_commission_type: "PERPLAN",
                              });
                              // setSelectedTab("PERPLAN")
                            }}
                          >
                            <FormControlLabel
                              value="Online"
                              control={<Radio />}
                              checked={
                                manageCommissionData?.agent_commission_type ===
                                "PERPLAN"
                              }
                              label={
                                <div className="flex gap-4 items-center">
                                  Per Plan
                                </div>
                              }
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    {manageCommissionData?.agent_commission_type ===
                      "PERCUSTOMER" && (
                      <>
                        {/* <div className="pt-[20px] pb-[10px]">
                                                        <p className="fieldText">Enter Percentage</p>
                                                        <div className="w-[300px]">
                                                            <TextField
                                                                sx={{
                                                                    "& *": {
                                                                        fontFamily: "Manrope !important",
                                                                        fontSize: "14px",
                                                                    },
                                                                }}
                                                                variant="standard"
                                                                fullWidth={true}
                                                                name="agent_commission_value"
                                                                value={manageCommissionData.agent_commission_value_type == 'PERCENTAGE' ? manageCommissionData.agent_commission_value : ""}
                                                                onChange={(e) => manageCommissionDataChange(e, 'PERCENTAGE')}
                                                            />
                                                        </div>
                                                    </div>
                                                    <p className="py-[10px] fieldText"> Or </p> */}
                        <div className="pt-[40p] pb-[40px]">
                          <p className="fieldText">Set Amount</p>
                          <div className="w-[300px]">
                            <TextField
                              sx={{
                                "& *": {
                                  fontFamily: "Manrope !important",
                                  fontSize: "14px",
                                },
                              }}
                              variant="standard"
                              fullWidth={true}
                              name="agent_commission_value"
                              value={
                                manageCommissionData.agent_commission_value_type ==
                                "AMOUNT"
                                  ? manageCommissionData.agent_commission_value
                                  : ""
                              }
                              onChange={(e) =>
                                manageCommissionDataChange(e, "AMOUNT")
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}

                    {manageCommissionData?.agent_commission_type ===
                      "PERPLAN" && (
                      <>
                        <div className="pt-[20px] pb-[10px]">
                          <p className="fieldText">Enter Percentage</p>
                          <div className="w-[300px]">
                            <TextField
                              sx={{
                                "& *": {
                                  fontFamily: "Manrope !important",
                                  fontSize: "14px",
                                },
                              }}
                              variant="standard"
                              fullWidth={true}
                              name="agent_commission_value"
                              value={
                                manageCommissionData.agent_commission_value_type ==
                                "PERCENTAGE"
                                  ? manageCommissionData.agent_commission_value
                                  : ""
                              }
                              onChange={(e) =>
                                manageCommissionDataChange(e, "PERCENTAGE")
                              }
                            />
                          </div>
                        </div>
                        {/* <p className="py-[10px] fieldText"> Or </p> */}
                        {/* <div className="pt-[40p] pb-[40px]">
                                                        <p className="fieldText">Set Amount</p>
                                                        <div className="w-[300px]">
                                                            <TextField
                                                                sx={{
                                                                    "& *": {
                                                                        fontFamily: "Manrope !important",
                                                                        fontSize: "14px",
                                                                    },
                                                                }}
                                                                variant="standard"
                                                                fullWidth={true}
                                                                name="agent_commission_value"
                                                                value={manageCommissionData.agent_commission_value_type == 'AMOUNT' ? manageCommissionData.agent_commission_value : ""}
                                                                onChange={(e) => manageCommissionDataChange(e, 'AMOUNT')}
                                                            />
                                                        </div>
                                                    </div> */}
                      </>
                    )}
                  </div>
                </div>
                <div className="flex gap-2">
                  <ActionButton
                    variant={"default"}
                    title={"Cancel"}
                    onClick={() => setAddCommitionModalOpen(false)}
                  />

                  <ActionButton
                    variant={"primary"}
                    title={"Update"}
                    onClick={handleAddCommissionSubmit}
                  />
                </div>
              </div>
            </Dialog>
          </div>
        )}
      </DashboardLayout>
    </>
  );
};

export default Agent;
