import React, { useEffect, useState } from "react";
import { BazaarCard } from "@/components/molecules/Bazaars";
import { Button, Grid, IconButton, Pagination, Tooltip } from "@mui/material";
import { useBazaarCardStyles } from "@/static/stylesheets/molecules";
import { AppService } from "@/service/AllApiData.service";
import { useNavigate } from "react-router-dom";
import LogoEdit from "@/static/icons/ic_edit.png";
import LaptopIcon from "@/static/icons/ic_laptop.png";
import { routePaths } from "@/routes";
import Loading from "@/components/LoadingCom/Loading";
import { useAuth } from "@/context/authContext";
import { Edit, Visibility } from "@mui/icons-material";

const BazaarsGridView = (props: any) => {
  const classes = useBazaarCardStyles();
  const navigate = useNavigate();
  const [getAllBazar, setGetAllBazar] = useState([]);
  const [totalCount, setTotalRows] = useState(10);
  // const data = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [loading, setloading] = useState(true);

  const handlePageChange = (event: any, value: any) => {
    setCurrentPage(value);
    // getAllLists(value);
  };
  const search = props.searchItem;
  const [step, setStep] = useState(null);

  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const endIndex = startIndex + ITEMS_PER_PAGE;
  const currentItems = getAllBazar;

  const getAllLists = async () => {
    let params = {
      page: search ? 1 : currentPage,
      search: search,
    };
    const responseJson = await AppService.getAllBazarList(params);
    if (responseJson.data.results) {
      setloading(false);
    }
    setGetAllBazar(responseJson.data.results);
    setTotalRows(responseJson.data.count);
    // console.log("ecomprd", responseJson.data.results);
  };
  const getViaID = async (ID: any) => {
    // console.log(ID.id);
    localStorage.setItem("IDS", ID.id);
    navigate(`/electronicsbazaar/${ID.id}`);
  };

  // const GetALLDATA = getAllBazar?.filter((elm: any) => elm?.bazaar_name?.toLowerCase().includes(search)).length > 0 ? getAllBazar?.filter((elm: any) => elm?.bazaar_name?.toLowerCase().includes(search)) : getAllBazar;

  useEffect(() => {
    getAllLists();
  }, [search, currentPage]);

  console.log("getAllBazar", getAllBazar);

  const { isAuthenticated, checkLogin, permissions } = useAuth();
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={classes.root}>
          <div className="grid grid-cols-1 xl:grid-cols-2 gap-4">
            {getAllBazar === undefined ? (
              <></>
            ) : (
              getAllBazar.map((addr: any) => (
                <div style={{ border: "1px solid #E1E1E1" }}>
                  <div
                    className={classes.root}
                    onClick={
                      () => {}
                      // getViaID(addr)
                    }
                  >
                    <div className={classes.bazaarContainer}>
                      <div className="flex">
                        <Grid
                          container
                          spacing={2}
                          style={{ alignItems: "center" }}
                        >
                          <Grid item xs={8} md={7}>
                            <div className="border-r-1 bazaarInfo">
                              <div className="brandInfo">
                                <div className={"flex justify-between"}>
                                  <img
                                    className="brandLogo"
                                    src={addr.bazaar_image}
                                    alt={"Logo"}
                                    // onError={(e: any) => {e.target.onerror = null; e.target.src=LaptopIcon}}
                                  />
                                </div>
                                <p className="bazaarTitle">
                                  {addr.bazaar_name}
                                </p>
                                <p className="bazaarSubTitle  w-40 truncate">
                                  {addr.bazaar_description}
                                </p>
                              </div>
                            </div>
                          </Grid>
                          <Grid item xs={4} md={5}>
                            <div className="bazaarContainer">
                              <div className={classes.wholesalerCard}>
                                <div className="brandCounter">
                                  <div className="container">
                                    <p className="counterTitle">
                                      {Number(addr.no_of_wholesalers)}
                                    </p>
                                    <p className="counterSubtitle">
                                      Wholesaler
                                    </p>
                                  </div>
                                  <div className="container">
                                    <p className="counterTitle">
                                      {Number(addr.no_of_agents)}
                                    </p>
                                    <p className="counterSubtitle">Agents</p>
                                  </div>
                                  <div className="container">
                                    <p className="counterTitle">
                                      {Number(addr.no_of_states)}
                                    </p>
                                    <p className="counterSubtitle">States</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Grid>
                        </Grid>
                      </div>

                      <div className={classes.revenueContainer}>
                        <div className={classes.revenueCard}>
                          <div className="container">
                            <div>
                              <p className="headTitle">Total Revenue Earned</p>
                              <p className="headSubtitle">
                                {addr.total_revenue}
                              </p>
                            </div>

                            <div>
                              <p className="headTitle">No. of Bills</p>
                              <p className="headSubtitle">{addr.no_of_bills}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={classes.revenueContainer}>
                        <div className={classes.revenueCard}>
                          <div className="container">
                            <div>
                              <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{
                                  backgroundColor: "#FF6652",
                                  ":hover": {
                                    backgroundColor: "#FF6652",
                                  },
                                }}
                                onClick={() => getViaID(addr)}
                                startIcon={<Visibility />}
                              >
                                View
                              </Button>
                            </div>

                            <div>
                              <Tooltip
                                title={
                                  permissions.includes("bazaar.update")
                                    ? ""
                                    : "Not authorized"
                                }
                                placement="bottom"
                              >
                                <Button
                                  fullWidth
                                  variant="outlined"
                                  color="primary"
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    // navigate(routePaths?.newbazaars);
                                    if (
                                      !permissions.includes("bazaar.update")
                                    ) {
                                      return;
                                    }
                                    navigate(routePaths?.newbazaars, {
                                      state: {
                                        Bstep: addr.bazaar_step,
                                        Bid: addr.id,
                                      },
                                    });
                                  }}
                                  startIcon={<Edit />}
                                >
                                  Edit
                                </Button>
                              </Tooltip>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            )}
            <div
              className="flex items-center justify-between pagination my-[40px] col-span-full"
              style={{ display: "flex", width: "100%" }}
            >
              <div className="text-[#84818A] text-sm font-medium">
                Show <span>{totalCount}</span> from {getAllBazar.length} bazaars
              </div>
              <Pagination
                count={Math.ceil(totalCount / 10)}
                page={currentPage}
                onChange={handlePageChange}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export { BazaarsGridView };
